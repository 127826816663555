.trail-course-infos-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 48px;

  width: 100%;
}

.trail-course-infos-wrapper__course-image {
  width: 35%;
  height: auto;

  border-radius: 8px;
}

.trail-course-infos-wrapper__course-infos {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 32px;
}

.course-infos__course-bio {
  display: flex;
  flex-direction: column;
  gap: 12px;

  width: 100%;
}

.course-bio__trail-name {
  display: flex;
  align-items: center;
  gap: 12px;

  img {
    height: 32px !important;
    width: 32px !important;
  }

  h2 {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: var(--dark);

    white-space: nowrap;
  }
}

.trail-name__line-divisor {
  border-color: rgba(0, 0, 0, 0.06);

  height: 1px;
  width: 100%;
}

.course-bio__trail-title {
  color: var(--dark);
  font-size: 26px;
  font-weight: 600;
  line-height: 36px;
}

.course-bio__trail-description {
  font-size: 14px;
  line-height: 21px;
  color: #34445380;
}

.course-bio__progress-bar {
  display: flex;
  justify-content: center;
  gap: 12px;

  width: 50%;
}

.progress-bar__progress {
  width: 35% !important;
}

.progress-bar__icon {
  background-color: var(--blue-gray);

  border-radius: 100%;

  height: 35px;
  width: 40px;
}

.course-infos__course-icon-infos {
  display: flex;
  align-items: center;
  gap: 40px;

  width: 100%;
}

.course-icon-infos {
  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 14px;
  line-height: 21px;
  color: var(--dark);
}

.course-icon-infos__label {
  font-size: 12px;
  line-height: 18px;
  color: #34445380;
}
