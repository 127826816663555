.lancamento-aula-edit__title-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lancamento-aula-edit-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.lancamento-aula-edit-wrapper__title-button {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-size: 24px;
    font-weight: 600;
    color: var(--dark);
  }
}
