.title-avaliacao-trilha{
  text-align: center;
  font-size: 24px;
  font-weight: 700;
}

#box-questao > p > figure > img {
  width: 630px;
  height: 350px;
}
