.header-avaliacao {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 36px;
  align-self: stretch;
}

.tile-avaliacao {
  color: var(--dark, #344453) !important;
  font-family: Roboto !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 32px !important;
  /* 160% */
}

.box-questao-avaliacao {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.box-questao-avaliacao p {
  width: 100%;
  height: 102px;

  color: var(--dark, #344453);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 160% */

  opacity: 0.800000011920929;
}
