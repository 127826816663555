.top-app-bar {
  width: 100%;

  z-index: 10;

  background-color: var(--white-color);

  position: fixed;

  box-shadow: 0 4px 4px var(--cinza-color-20);
  padding: 12px 48px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top-app-bar__logo {
  width: auto;
  height: 64px;
}

.top-app-bar__profile-box {
  display: flex;
  align-items: center;
  gap: 12px;

  cursor: pointer;

  img {
    width: 35px;
    height: 35px;
  }
}

.profile-box__name-type {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.name-type__name {
  font-weight: 600;
  font-size: 16px;
  color: var(--dark);
}

.name-type__type {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: var(--dark-secondary);
}
