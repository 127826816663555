.horario {
  margin-bottom: 15px;

  .checkbox-true {
    background: rgba(31, 97, 204, 0.5);
    border-radius: 8px;
    width: 50px;
    height: 50px;

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: var(--primary-color);
      border-color: var(--primary-color);
    }
  }

  .checkbox-false {
    background: #cfd8dc;
    border-radius: 8px;
    width: 50px;
    height: 50px;

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: var(--primary-color);
      border-color: var(--primary-color);
    }
  }

  .horario-box {
    background: #ffffff;
    border: 1px solid rgba(52, 68, 83, 0.05);
    box-sizing: border-box;
    border-radius: 4px;
    width: 195px;
    height: auto;
    padding: 10px 15px;

    h4 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: var(--dark);
    }

    h6 {
      font-family: Open Sans !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 16px !important;
      line-height: 24px !important;
      color: var(--gray-light) !important;
    }
  }
}
