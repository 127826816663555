.porcentagem-aulas {
  display: flex;
  align-items: center;
  gap: 16px;

  padding: 24px;

  width: auto;
  height: 100%;

  background: var(--white-color);

  border-radius: 8px;
}

.porcentagem-aulas__andamento-aulas {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
}

.andamento-aulas__titulo {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #344453;
  margin-bottom: 0px;
}

.andamento-aulas__subtitulo {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #485d73;
}
