.box-data-turma {
  display: flex;
  flex-direction: column;
  gap: 24px;

  width: 100%;
  height: fit-content;

  background-color: var(--white-color);

  padding: 24px;

  border-radius: 8px;

  .box-data-turma__turma-descricao {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}

.turma-descricao__titulo {
  font-size: 22px;
  line-height: 33px;
  color: #344453;

  &::first-letter {
    text-transform: uppercase;
  }
}

.turma-descricao__status-dias {
  display: flex;
  gap: 16px;
}

.status-dias__data {
  font-size: 12px;
  line-height: 18px;
  color: #6a7c96;
}

.box-data-turma__info-icones {
  display: flex;
  flex-direction: row;
  column-gap: 24px;
  row-gap: 12px;
  flex-wrap: wrap;
  align-items: center;
}

.info-icones {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  p {
    font-size: 14px;
    line-height: 20px;
    color: #6a7c96;
  }

  svg {
    color: #6a7c96;
    width: 20px;
    height: 20px;
  }
}

.box-data-turma__datas-horarios {
  display: flex;
  align-items: center;
  gap: 8px;

  overflow-x: auto;
  overflow-y: hidden;
}

.datas-horarios {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  background: var(--blue-color-20);

  border-radius: 4px;

  padding: 8px;

  width: auto;
  max-width: 100%;
}

.datas-horarios__dia-semana {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: var(--primary-color);
}

.datas-horarios__horario {
  font-size: 12px;
  line-height: 18px;
  color: var(--blue-light);
}
