.modal-container {
  width: 100vw;
  height: 100vh;
  background-color: #00000096;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 10000;
  margin: 0px;

  .modal {
    padding: 30px 0px;
    position: relative;
    width: 540px;
    min-height: 250px;
    height: auto;
    background-color: var(--white-color);
    box-shadow: 1px 3px 11px rgba(105, 105, 105, 0.15);
    border-radius: 8px;

    .circle {
      width: 120px;
      height: 120px;
      background-color: rgba(30, 190, 94, 0.2);
      border-radius: 60px;
    }

    h1 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 26px;
      line-height: 36px;
      text-align: center;
      color: var(--dark);
    }

    p {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: rgba(52, 68, 83, 0.5);
      margin: 0px 30px;
    }

    .button-box {
      padding: 0px 30px 0px 30px;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .space {
    height: 150px;
  }

  .img-success {
    position: absolute;
    left: 120px;
    top: -100px;
  }

  .modal-inscricao {
    padding: 30px 30px;
    position: relative;
    width: 540px;
    min-height: 250px;
    height: auto;
    background-color: var(--white-color);
    box-shadow: 1px 3px 11px rgba(105, 105, 105, 0.15);
    border-radius: 8px;

    h1 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 26px;
      text-align: left;
      color: var(--dark);
    }

    h3 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      color: var(--dark);
    }

    .trilha {
      h2 {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: var(--blue-gray);

        span {
          color: var(--trilha-digital);
        }
      }
    }

    .circle-gray {
      width: 50px;
      height: 40px;
      border-radius: 25px;
      background-color: rgba(203, 212, 237, 0.2);
      margin-right: 10px;
    }

    h4 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      color: var(--dark);
    }

    h6 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: var(--gray-light);
    }

    .box-radio {
      width: 480px;
      height: auto;
      padding: 20px;
      background-color: transparent;
      border: 1px solid var(--primary-color);
      box-sizing: border-box;
      box-shadow: 0px 0px 4px rgba(26, 77, 161, 0.5);
      border-radius: 20px;

      .ant-radio-wrapper {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 26px;
        color: var(--dark);

        span {
          p.texto-radio {
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            margin-bottom: 0px !important;
            color: var(--secondary-color);
          }
        }
      }
    }

    .box-radio-select {
      width: 480px;
      height: auto;
      padding: 20px;
      background-color: var(--primary-color);
      border: 1px solid var(--primary-color);
      box-sizing: border-box;
      box-shadow: 0px 0px 4px rgba(26, 77, 161, 0.5);
      border-radius: 20px;

      .ant-radio-wrapper {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 26px;
        color: var(--white-color);

        span {
          p.texto-radio {
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            margin-bottom: 0px !important;
            color: var(--white-color);
          }
        }
      }
    }
  }
}
