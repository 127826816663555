.student-accordion {
  background-color: var(--white-color);

  width: 100%;

  border-radius: 8px;
}

.student-accordion__top-content {
  width: 100%;

  display: flex;
  align-items: center;
  gap: 12px;

  cursor: pointer;

  padding: 16px;
}

.top-content__student-infos {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.student-infos__student-name {
  color: var(--dark);
  font-weight: 600;
  font-size: 16px;
}

.student-infos__matricula-status {
  display: flex;
  align-items: center;
  gap: 12px;
}

.matricula-status__id-matricula {
  color: var(--dark-secondary);
  font-size: 12px;
}

.student-accordion__divisor {
  width: 100%;
  height: 1px;

  border: 1px solid var(--blue-gray);
}

.student-accordion__buttons {
  width: 100%;

  padding: 16px;

  display: flex;
  justify-content: flex-end;
}

