.container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1{
    font-size: 28px;
    padding: 12px 0px;
  }
  ul{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 18px;
    padding: 12px;
    background-color: #b6c0d326;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
}
.itemContainer{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 20%;
}
.item{
  display: flex;
  gap: 18px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img{
    height: 100px;

    &:hover{
      cursor: pointer;
      transform: scale(1.1);
      transition: all 0.3s ease;
    }
  }
}