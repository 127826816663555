.box-historico {
  background: var(--white-color);
  border: 1px solid #ebeffc;
  box-sizing: border-box;
  border-radius: 4px;
  width: 32%;
  height: auto;
  padding: 20px;
  position: relative;
  margin-bottom: 20px;

  h1#title-historico {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: black;
  }

  .dot-green {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin-right: 5px;
    background-color: var(--check);
  }

  h5#green {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: var(--check);
  }

  .dot-cinza {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin-right: 5px;
    background-color: rgba(52, 68, 83, 0.5);
  }

  h5#cinza {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: rgba(52, 68, 83, 0.5);
  }

  .box-data {
    background: rgba(176, 203, 81, 0.15);
    border-radius: 4px;
    padding: 8px;
    width: auto !important;

    span {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: var(--dark-green);
      margin-right: 5px;
    }

    h6 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: var(--dark-green);
    }
  }

  .dot {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .button-historico {
    position: absolute;
    right: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
  }
}

.box-historico-50 {
  background: var(--white-color);
  border: 1px solid #ebeffc;
  box-sizing: border-box;
  border-radius: 4px;
  width: 48%;
  height: auto;
  padding: 20px;
  position: relative;

  h1#title-historico {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: black;
  }

  .dot-green {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin-right: 5px;
    background-color: var(--check);
  }

  h5#green {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: var(--check);
  }

  .dot-cinza {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin-right: 5px;
    background-color: rgba(52, 68, 83, 0.5);
  }

  h5#cinza {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: rgba(52, 68, 83, 0.5);
  }

  .box-data {
    background: rgba(176, 203, 81, 0.15);
    border-radius: 4px;
    padding: 8px;
    width: auto !important;

    span {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: var(--dark-green);
      margin-right: 5px;
    }

    h6 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: var(--dark-green);
    }
  }

  .dot {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .button-historico {
    position: absolute;
    right: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
  }
}
