.dashboard {
  p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: rgba(52, 68, 83, 0.5);
    margin-bottom: 0px;
  }

  h5 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: var(--gray-light);
  }

  img.imageCurso {
    margin-right: 15px;
  }

  .table {
    .table-head {
      background-color: var(--white-color);
      height: 50px;
      padding: 5px 20px 5px 0px;

      li {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: var(--dark-secondary);
        margin-left: 20px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .icon {
          margin-top: 30px !important;
        }
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .table {
    overflow-y: scroll;
  }

  .row-items,
  .dashboard .table .table-head {
    width: 970px !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .table {
    overflow-y: scroll;
  }

  .row-items,
  .dashboard .table .table-head {
    width: 970px !important;
  }
}

@media screen and (max-width: 767px) {
  .table {
    overflow-y: scroll;
  }

  .row-items,
  .dashboard .table .table-head {
    width: 970px !important;
  }
}
