@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

html {
  overflow-x: hidden;
}

:root {
  --primary-color: #1a4da1;
  --primary-color-20: rgba(31, 97, 204, 0.2);
  --primary-color-15: rgba(26, 77, 161, 0.15);

  --red-color: #ff4d4f;
  --orange-color: #ff975b;

  --white-color: #ffffff;
  --white-color-25: #f5f5f9;
  --white-color-50: rgba(255, 255, 255, 0.5);
  --cinza-color-20: rgba(203, 212, 237, 0.2);

  --blue-dark: #0d306b;
  --blue-transparent: #92a9cb;
  --blue-light: #1f61cc;
  --blue-color-20: rgba(26, 77, 161, 0.2);
  --blue-color-50: rgba(31, 97, 204, 0.5);

  --green-color: #b0cb51;

  --main-font: 'Open Sans', sans-serif;
}

.global-spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

* {
  padding: 0;
  margin: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', sans-serif;
  color: #000000;
  -webkit-font-smoothing: antialiased;
  background-color: #f5f5f9 !important;
}

img {
  max-width: 100%;
  height: auto;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
  color: #485d73 !important;
}

button {
  cursor: pointer;
  border-width: 0px;
  border: none;
  border-color: transparent !important;
}

select {
  border-width: 0px;
  background-color: transparent;
}

*:focus {
  outline: none !important;
  box-shadow: transparent !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block-start: 0;
  margin-block-end: 0;
}

input,
.input {
  border: 0px hidden !important;
  height: 40px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-transition-delay: 99999s;
}

input:-internal-autofill-selected {
  background-color: none !important;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.dropdown {
  background-color: blue;
}

.container-fondo {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f9;
  padding-top: 40px;
}

.button-primary {
  background: var(--primary-color) !important;

  border-radius: 8px !important;
  border: 0px !important;

  color: #ffffff !important;

  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 24px !important;

  max-width: 100% !important;
  height: 40px !important;

  display: flex !important;
  justify-content: center;
  align-items: center;

  padding: 0 24px !important;

  &:hover {
    background-color: var(--blue-dark) !important;
  }

  &:disabled {
    background-color: var(--cinza-color-20) !important;
    color: #31313148 !important;
  }
}

.button-second {
  background-color: #ebeffc !important;

  color: var(--primary-color) !important;
  font-family: Open Sans !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  text-align: center !important;

  height: 40px !important;
  max-width: 100% !important;

  border: 0px !important;
  border-radius: 8px !important;

  display: flex !important;
  justify-content: center;
  align-items: center;

  padding: 0 16px !important;
}

.button-second:hover {
  background-color: #d7e1f7 !important;
}

.button-inative {
  background: #ebeffc !important;
  border-radius: 4px !important;
  color: #92a9cb !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  text-align: center !important;
  height: 40px !important;
  max-width: 100% !important;
  border: 0px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px !important;
}

.button-line {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

  padding: 8px 16px !important;

  height: fit-content !important;

  border: 2px solid #ebeffc !important;
  border-radius: 8px !important;

  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 24px !important;

  color: var(--primary-color) !important;

  box-shadow: none;
}

.button-line:hover {
  background-color: #1a4da108 !important;
}

.button-proximo {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 8px 16px !important;
  max-width: 100% !important;
  height: 40px !important;
  border: 1.5px solid #ebeffc !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  text-align: center !important;
  color: var(--green-color) !important;
  margin-bottom: 15px !important;
}

.button-line-red {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 8px 16px !important;
  max-width: 100% !important;
  height: 40px !important;
  border: 1.5px solid #ebeffc !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  text-align: center !important;
  color: var(--red-color) !important;
  margin-bottom: 15px !important;
}

.button-danger {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 8px 16px !important;
  max-width: 100% !important;
  height: 40px !important;
  background: #ff4d4f !important;
  border-radius: 4px !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  text-align: center !important;
  color: #ffffff !important;
  margin-bottom: 15px !important;
}

.button-fechar {
  position: absolute !important;
  right: 108px !important;
  top: 124px !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 8px !important;
  width: 40px !important;
  height: 40px !important;
  background: #ebeffc !important;
  border-radius: 8px !important;
  color: #344453 !important;
}

.button-fechar:hover {
  color: #6240b0 !important;
}

.progress-user .ant-progress-success-bg,
.ant-progress-bg {
  height: 5px !important;
  background-color: #895cf2 !important;
}

.progress-user .ant-progress-inner {
  background-color: rgba(184, 154, 253, 0.5) !important;
}

.boxes .ant-checkbox-wrapper .ant-checkbox-inner,
.boxes .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.boxes .ant-checkbox:hover .ant-checkbox-inner,
.boxes .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #895cf2 !important;
  border-radius: 16px !important;
}

.ant-checkbox-wrapper::after {
  content: none !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 0px solid #d9d9d9 !important;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
  border: 0px solid transparent !important;
  outline: none !important;
}

.ant-input-affix-wrapper .ant-input:focus,
.ant-input:focus,
.ant-input-focused {
  border: none !important;
  border-bottom: 1px solid white !important;
  box-shadow: none !important;
}

span.ant-input-affix-wrapper .ant-input-affix-wrapper-focused {
  outline: none !important;
  border: 1px solid #ffffff !important;
}

.ant-input-affix-wrapper {
  border: 1px solid #ffffff !important;
  padding: 0px !important;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 0px rgb(24 144 255 / 20%) !important;
}

.ant-input,
.ant-picker,
.ant-picker:hover,
.ant-picker-focused {
  border: 0px solid #ffffff !important;
}

textarea.ant-input {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #485d73;
}

.filtros .filtro-estado a.ant-dropdown-trigger.ant-dropdown-link {
  color: #485d73 !important;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

.filtros .filtro-estado a.ant-dropdown-trigger.ant-dropdown-link span {
  margin-right: 0px !important;
}

.ant-picker-focused {
  border-color: #ffffff !important;
  border-right-width: 0px !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0px rgb(24 144 255 / 20%) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: var(--primary-color) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid var(--primary-color) !important;
}

.ant-radio-group .ant-radio-group-outline,
.user-row .ant-radio-group {
  width: 100% !important;
}

.ant-radio-checked::after,
.ant-radio-checked .ant-radio-inner,
.ant-radio-inner:hover {
  border: 1px solid var(--primary-color) !important;
}

.ant-radio-inner::after,
.ant-checkbox-checked::after {
  background-color: var(--primary-color) !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border: 1px solid var(--primary-color) !important;
}

.ant-select-focused {
  border: 0px solid var(--primary-color) !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  box-shadow: 0 0 0 0px rgb(24 144 255 / 20%) !important;
}

.ant-select:not(.ant-select-disabled):hover {
  border-right-width: 0px !important;
}

/* .ant-input-number:focus, .ant-input-number-focused{
  box-shadow: 0 0 0 0px rgb(24 144 255 / 20%) !important;
}

.ant-input-number:hover, .ant-input-number:focus{
  border: 1px solid #895CF2 !important;
}

.ant-input-number{
  padding: 0px !important;
  border: 1px solid rgba(184, 154, 253, 0.5) !important;
  border-radius: 8px !important;
  height: 40px !important;
}

.ant-input-number-handler-wrap{
  border-radius: 0px 8px 8px 0px !important;
} */

.ant-input-group-addon {
  color: #6a7c96 !important;
  background-color: #fafafa !important;
  border: 0px solid #d9d9d9 !important;
}

.ant-radio-group {
  font-size: 14px !important;
}

.ant-form-item {
  margin-bottom: 0px !important;
}

.upload-component {
  width: 100%;
}

.upload-component span:first {
  width: 100%;
}

.upload-component .ant-upload.ant-upload-select {
  display: block !important;
}

.ant-switch-checked {
  background-color: var(--primary-color) !important;
}

.ant-switch-checked:focus {
  box-shadow: 0 0 0 2px rgba(184, 154, 253, 0.5) !important;
}

.inputMoneyCustom .ant-input-number-handler-wrap {
  visibility: hidden;
}

#input-dashboard
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
#input-usuario
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background: #f5f5f9 !important;
}

.ant-select .ant-select-single .ant-select-show-arrow {
  border-right-width: 0px !important;
}

.MuiPaginationItem-root {
  color: #ff975b !important;
}

.MuiPaginationItem-page.Mui-selected,
.MuiPaginationItem-page.Mui-selected:hover {
  background-color: #ffffff !important;
  border: 1px solid #ff975b !important;
  color: #ff975b !important;
}

.MuiPaginationItem-page:hover {
  color: #ffffff !important;
  background-color: #ff975b !important;
}

#input-dashboard .ant-picker {
  background: var(--cinza-color-20) !important;
}

.ant-upload.ant-upload-select-picture-card {
  width: 115px !important;
  height: 115px !important;
  background-color: unset !important;
  border: unset !important;
}

.ant-upload-list-picture-card .ant-upload-list-item {
  width: 115px !important;
  height: 115px !important;
  border-radius: 60px !important;
}

.ant-upload-list-picture-card .ant-upload-list-item-info {
  width: 115px !important;
  height: 115px !important;
  border-radius: 60px !important;
}

.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  padding: 0px !important;
}

.ant-upload-picture-card-wrapper {
  /* display: flex!important;
    justify-content: center!important; */
  width: unset !important;
}

.ant-input-number {
  border: 0px !important;
  width: 100%;
}

.ant-input-number-focused {
  box-shadow: none !important;
}

.pointer {
  cursor: pointer;
}

.ant-drawer-body {
  overflow-x: scroll !important;
}

.ant-select-dropdown {
  min-width: 120px !important;
}

.flex {
  display: flex;
}

.col {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.strong {
  font-weight: 700;
}

/***__ Configuração padrão Cursor __***/

.cursor {
  cursor: pointer;
}

/***__ Configuração padrão de tamanho de elementos __***/

.wm-content {
  width: max-content !important;
}

.wp5 {
  width: 5% !important;
}

.wp10 {
  width: 10% !important;
}

.wp15 {
  width: 15% !important;
}

.wp20 {
  width: 20% !important;
}

.wp25 {
  width: 25% !important;
}

.wp30 {
  width: 30% !important;
}

.wp35 {
  width: 35% !important;
}

.wp40 {
  width: 40% !important;
}

.wp45 {
  width: 45% !important;
}

.wp50 {
  width: 50% !important;
}

.wp55 {
  width: 55% !important;
}

.wp60 {
  width: 60% !important;
}

.wp65 {
  width: 65% !important;
}

.wp70 {
  width: 70% !important;
}

.wp75 {
  width: 75% !important;
}

.wp80 {
  width: 80% !important;
}

.wp85 {
  width: 85% !important;
}

.wp90 {
  width: 90% !important;
}

.wp95 {
  width: 95% !important;
}

.wp100 {
  width: 100% !important;
}

.wvw100 {
  width: 100vw !important;
}

.hp5 {
  height: 5% !important;
}

.hp10 {
  height: 10% !important;
}

.hp15 {
  height: 15% !important;
}

.hp20 {
  height: 20% !important;
}

.hp25 {
  height: 25% !important;
}

.hp30 {
  height: 30% !important;
}

.hp35 {
  height: 35% !important;
}

.hp40 {
  height: 40% !important;
}

.hp45 {
  height: 45% !important;
}

.hp50 {
  height: 50% !important;
}

.hp55 {
  height: 55% !important;
}

.hp60 {
  height: 60% !important;
}

.hp65 {
  height: 65% !important;
}

.hp70 {
  height: 70% !important;
}

.hp75 {
  height: 75% !important;
}

.hp80 {
  height: 80% !important;
}

.hp85 {
  height: 85% !important;
}

.hp90 {
  height: 90% !important;
}

.hp95 {
  height: 95% !important;
}

.hp100 {
  height: 100% !important;
}

.hvh100 {
  height: 100vh !important;
}

/***__ Configuração padrão de Margin __***/
.mat {
  margin: auto;
}

.m5 {
  margin: 5px;
}

.m10 {
  margin: 10px;
}

.m15 {
  margin: 15px;
}

.m20 {
  margin: 20px;
}

.m25 {
  margin: 25px;
}

.m30 {
  margin: 30px;
}

.m35 {
  margin: 35px;
}

.m40 {
  margin: 40px;
}

.m45 {
  margin: 45px;
}

.m50 {
  margin: 50px;
}

/* Margin Top */
.mt5 {
  margin-top: 5px;
}

.mt10 {
  margin-top: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mt25 {
  margin-top: 25px;
}

.mt30 {
  margin-top: 30px;
}

.mt35 {
  margin-top: 35px;
}

.mt40 {
  margin-top: 40px;
}

.mt45 {
  margin-top: 45px;
}

.mt50 {
  margin-top: 50px;
}

/* Margin Right */
.mr5 {
  margin-right: 5px;
}

.mr10 {
  margin-right: 10px;
}

.mr15 {
  margin-right: 15px;
}

.mr20 {
  margin-right: 20px;
}

.mr25 {
  margin-right: 25px;
}

.mr30 {
  margin-right: 30px;
}

.mr35 {
  margin-right: 35px;
}

.mr40 {
  margin-right: 40px;
}

.mr45 {
  margin-right: 45px;
}

.mr50 {
  margin-right: 50px;
}

/* Margin Left */
.ml5 {
  margin-left: 5px;
}

.ml10 {
  margin-left: 10px;
}

.ml15 {
  margin-left: 15px;
}

.ml20 {
  margin-left: 20px;
}

.ml25 {
  margin-left: 25px;
}

.ml30 {
  margin-left: 30px;
}

.ml35 {
  margin-left: 35px;
}

.ml40 {
  margin-left: 40px;
}

.ml45 {
  margin-left: 45px;
}

.ml50 {
  margin-left: 50px;
}

/* Margin Bottom */
.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb25 {
  margin-bottom: 25px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb35 {
  margin-bottom: 35px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb45 {
  margin-bottom: 45px;
}

.mb50 {
  margin-bottom: 50px;
}
