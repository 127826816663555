.hamburger {
  width: 1.3rem;
  height: 1.7rem;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  z-index: 10000;
}

.burger {
  width: 1.7rem;
  height: 0.25rem;
  border-radius: 10px;
  background-color: black;
  transform-origin: 1px;
  transition: all 0.3s linear;
}

.burger-menu-none {
  display: none;
}

.burger-menu {
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  background-color: var(--blue-light);
  opacity: 0.95;
  z-index: 1000;
  padding: 70px 50px 60px 50px;
  transform: translatex(0%);

  ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    hr {
      width: 100%;
    }

    li {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 36px;
      line-height: 50px;
      color: var(--white-color);
      list-style: none;
    }

    li:hover {
      color: var(--secondary-color);
    }
  }
}
