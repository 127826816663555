.dashboard {
  .box-left {
    img.avatar {
      width: 80px;
      height: auto;
      margin: 0px 10px 0px 0px;
    }

    h1 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 26px;
      line-height: 36px;
      color: var(--dark);

      span {
        color: var(--secondary-color);
      }
    }

    h6 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 36px;
      color: var(--dark);

      span {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 36px;
        color: var(--dark);
      }
    }

    .curriculo {
      width: 100%;
      border-radius: 4px;
      padding: 5px 10px;
      background-color: var(--white-color);

      h5 {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 36px;
        color: var(--dark);
      }
    }

    ul {
      margin-left: 10px;

      li {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 36px;
        color: var(--dark);
      }
    }

    .ant-radio-group {
      width: 100%;

      .ant-radio-wrapper {
        width: 24%;
      }
    }

    h5 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      color: var(--dark);
    }

    h3.azul {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 36px;
      color: var(--primary-color);
    }
  }

  .box-data {
    background: rgba(176, 203, 81, 0.15);
    border-radius: 4px;
    padding: 8px;
    width: auto !important;

    span {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: var(--dark-green);
      margin-right: 5px;
    }

    h6 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: var(--dark-green);
    }
  }

  .box-tag {
    margin-top: 10px;
  }

  .box-mentor-chat {
    background: #ffffff;
    border: 1px solid rgba(52, 68, 83, 0.05);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 20px;
    position: relative;

    img {
      width: 80px;
      height: auto;
      margin-right: 10px;
    }

    p {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;
      color: var(--dark);
    }

    a {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      text-decoration-line: underline;
      color: #2979ff;
    }

    .box-mentor-message {
      img {
        width: 50px;
        height: auto;
        margin-right: 20px;
      }

      .message {
        background: #bbdefb;
        border-radius: 16px 16px 16px 0px;
        padding: 10px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 22px;
        color: var(--dark);
      }

      p {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 22px;
        color: #cfd8dc;
      }

      .message-user {
        background: #245fad;
        border-radius: 16px 16px 0px 16px;
        padding: 10px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 22px;
        color: var(--white-color);
      }
    }

    .clip {
      position: absolute;
      right: 20px;
      top: 20px;
    }

    textarea.ant-input {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: rgba(52, 68, 83, 0.5);
    }

    h6 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: right;
      color: rgba(52, 68, 83, 0.5);
    }
  }

  h6#subtitle-mentoria {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    color: var(--secondary-color);
  }

  h2 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: var(--dark);
  }

  .icone {
    width: 60px;
    height: 50px;
    border: 1px solid #cbd4ed;
    box-sizing: border-box;
    border-radius: 8px;
    margin-right: 10px;

    img.emoji {
      width: 40px;
      height: 40px;
    }
  }

  .box-line {
    border: 1px solid #cfd8dc;
    box-sizing: border-box;
    border-radius: 8px;
    max-height: 460px;
  }

  .box-white {
    .box-scroll {
      overflow-y: scroll;
      max-height: 275px;
    }

    h4#calendar {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: var(--gray-light);
    }

    h4#azul {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: var(--primary-color);
    }

    h4#title-mentorias {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      color: var(--secondary-color);
    }

    p#hora {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      text-transform: capitalize;
      color: rgba(52, 68, 83, 0.5);
    }

    .box-calendar-mentor {
      background: #ffffff;
      border: 1px solid #ebeffc;
      box-sizing: border-box;
      border-radius: 8px;
      width: 100%;
      height: auto;
      padding: 12px 12px 0px 12px;
      border-left: 7px solid #fbaf1f;

      h4 {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: var(--dark);

        span {
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    .box-calendar-mentor-blue {
      background: #ffffff;
      border: 1px solid #ebeffc;
      box-sizing: border-box;
      border-radius: 8px;
      width: 100%;
      height: auto;
      padding: 12px 12px 0px 12px;
      border-left: 7px solid var(--primary-color);

      h4 {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: var(--dark);

        span {
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    .select-box-mentoria {
      width: 34%;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: var(--secondary-color);
    }

    .day {
      width: 40px;
      height: 50px;
      margin-left: 7px;

      h4 {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-transform: capitalize;
        color: #485d73;
      }

      p {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        text-transform: capitalize;
        color: var(--secondary-color);
      }
    }

    .day-select {
      width: 40px;
      height: 50px;
      margin-left: 7px;
      background-color: var(--primary-color);
      border-radius: 8px;

      h4 {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-transform: capitalize;
        color: var(--white-color);
      }

      p {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        text-transform: capitalize;
        color: var(--white-color);
      }
    }
  }
}

@media (min-width: 1201px) and (max-width: 1300px) {
  .dashboard .box-white {
    max-height: 550px !important;
  }
  .calendar-days {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .dashboard .box-white {
    height: auto;
    max-height: 550px !important;
  }
  .calendar-days {
    flex-direction: column;
    align-items: flex-start;
  }

  #box-calendar-datas {
    flex-direction: column;
  }

  #calendar-mentoria,
  #calendar-boxes-mentoria {
    width: 100% !important;
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .w-49 {
    width: 100%;
  }

  .dashboard .box-white {
    height: auto;
    max-height: 700px !important;
    margin-bottom: 15px;
  }

  .calendar-days {
    flex-direction: column;
    align-items: flex-start;
  }

  .box-mentor {
    width: 49%;
  }

  .box-historico {
    width: 48%;
  }

  #box-calendar-datas {
    flex-direction: column;
  }

  #calendar-mentoria,
  #calendar-boxes-mentoria {
    width: 100% !important;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  .w-49 {
    width: 100%;
  }

  .calendar-days {
    flex-direction: column;
    align-items: flex-start;
  }

  .dashboard .box-white {
    height: auto;
    max-height: 700px !important;
    margin-bottom: 15px;
  }

  .box-mentor {
    width: 100%;
  }

  .box-historico {
    width: 100%;
  }

  #box-calendar-datas,
  #box-perfil {
    flex-direction: column;
  }

  #calendar-mentoria,
  #calendar-boxes-mentoria {
    width: 100% !important;
    margin-bottom: 10px;
  }

  #box-perfil {
    .box-left,
    #box-mentores-right {
      width: 100% !important;
    }
    #box-mentores-right {
      padding-bottom: 50px;
    }
  }
}
