.conteudo-aula-label-paragrafo {
  display: flex;
  flex-direction: column;
  gap: 4px;

}

.conteudo-aula-label-paragrafo__label {
  font-size: 14px;
  color: var(--dark-secondary);
}

.conteudo-aula-label-paragrafo__paragrafo {
  font-size: 16px;
  color: var(--dark);
}

.conteudo-aula-label-paragrafo__paragrafo-array {
  display: flex;
  gap: 4px;
}
