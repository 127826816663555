.all-cursos-container__all-cursos-title-back-button {
  display: flex;
  align-items: center;
  gap: 16px;

  width: 100%;
}

.all-cursos-container__curso-search-bar {
  width: 100%;

  position: relative;
}

.curso-search-bar__icon {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);

  height: 24px;
  width: 24px;
}

.curso-search-bar__search-bar-input {
  padding-left: 40px !important;

  border-radius: 8px;

  gap: 8px;

  width: 100%;

  height: 40px;
}

.all-cursos-container__cursos-tag-filter {
  display: flex;
  flex-direction: column;
  gap: 4px;

  label {
    font-size: 14px;
    font-weight: 400;
    color: #34445380;
    line-height: 24px;
  }
}

.cursos-tag-filter__tags-container {
  display: flex;
  align-items: center;
  gap: 8px;

  div {
    margin: 0 !important;

    span {
      text-transform: capitalize;
    }
  }
}

.all-cursos-container__trilha-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
