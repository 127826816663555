.franquia {
  position: static !important;
}

.box-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

h3.title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 48px;
  color: #353f49;
}

.filtros {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.input-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  width: 530px;
  height: 40px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid rgba(52, 68, 83, 0.05);
  box-sizing: border-box;
}

.input-text input {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-left: 5px;
  width: 100%;
  color: #6a7c96;
  background-color: #ffffff;
}

.filtro-estado {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  width: auto;
  height: 40px;
  background: var(--cinza-color-20);
  border-radius: 4px;
  margin-left: 10px;
}

.filtro-estado span,
.filtro-cidade span {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(52, 68, 83, 0.5);
}

.filtro-estado select,
.filtro-cidade select {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #485d73;
  margin-right: 10px;
}

.filtro-cidade {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  width: auto;
  height: 40px;
  background: rgba(106, 124, 150, 0.05);
  border-radius: 4px;
  margin-left: 10px;
}

.tabela {
  width: 100%;
  padding-bottom: 10px;
}

.titles-users {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 0px solid #cbd4ed;
  padding: 0px 0px 10px 0px;
  margin-bottom: 8px;
}

.arrows {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tabela .registro-user {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #ebeffc;
  padding-bottom: 17px;
  margin-bottom: 0pxpx;
  padding-top: 17px;
}

.tabela .registro-user .user {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 30%;
}

.tabela .registro-user .user img {
  width: 40px;
  height: 40px;
  margin-right: 16px;
}

.tabela .registro-user .user .nome {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.tabela .registro-user .user .nome p {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #344453;
  margin-bottom: 0px;
}

.tabela .registro-user .user .nome .estado-user {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
}

.tabela .registro-user .user .nome .estado-user .dot-verde {
  width: 8px;
  height: 8px;
  background-color: #1ebe5e;
  border-radius: 8px;
  margin-right: 7px;
}

.tabela .registro-user .user .nome .estado-user .dot-vermelho {
  width: 8px;
  height: 8px;
  background-color: #ff4d4f;
  border-radius: 8px;
  margin-right: 7px;
}

.tabela .registro-user .user .nome .estado-user p.ativo {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #1ebe5e;
}

.tabela .registro-user .user .nome .estado-user p.inativo {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #ff4d4f;
}

.tabela .registro-user .funcao-user {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 20%;
  padding: 5px 5px 5px 0px;
}

.tabela .registro-user .funcao-user .tag-admin,
.tag-admin {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  padding: 5px 10px 5px 10px;
  background: rgba(110, 194, 201, 0.2);
  border-radius: 500px;
}

.tabela .registro-user .funcao-user .tag-admin span,
.tag-admin span {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #306f75;
  margin-left: 5px;
}

.tabela .registro-user .funcao-user .tag-sede,
.tag-sede {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  padding: 5px 10px 5px 10px;
  background: rgba(127, 211, 143, 0.2);
  border-radius: 500px;
}

.tabela .registro-user .funcao-user .tag-sede span,
.tag-sede span,
#boxes-tags-detalhe .tag-sede span {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #478953;
  margin-left: 5px;
}

.tabela .registro-user .funcao-user p {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #6a7c96;
  margin-bottom: 0px;
}

.tabela .registro-user .funcao-user .tag-colaborador {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  padding: 5px 10px 5px 10px;
  background: rgba(255, 151, 91, 0.2);
  border-radius: 500px;
}

.tabela .registro-user .funcao-user .tag-colaborador span {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #cf6d34;
  margin-left: 5px;
}

.tabela .registro-user .funcao-user .tag-franquia {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  padding: 5px 10px 5px 10px;
  background: rgba(207, 107, 108, 0.2);
  border-radius: 500px;
}

.tabela .registro-user .funcao-user .tag-franquia span {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #aa5556;
  margin-left: 5px;
}

.tabela .registro-user .funcao-user .tag-instrutor {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  padding: 5px 10px 5px 10px;
  background: rgba(109, 180, 248, 0.2);
  border-radius: 500px;
}

.tabela .registro-user .funcao-user .tag-instrutor span {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #3771a8;
  margin-left: 5px;
}

.tabela .registro-user .funcao-user .tag-gestor,
.tag-gestor {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  padding: 5px 10px 5px 10px;
  background: rgba(184, 154, 253, 0.2);
  border-radius: 500px;
}

.tabela .registro-user .funcao-user .tag-gestor span,
.tag-gestor span,
#boxes-tags-detalhe .tag-gestor span {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #895cf2;
  margin-left: 5px;
}

/*Menu User Step*/

.menu-user {
  position: absolute;
  left: 126px;
  top: 129px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: auto;
  width: 290px;
  padding: 20px;
}

.menu-user h3 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  color: #1f2832;
}

/* Estilo Steps Component */

.menu-user
.ant-steps-item-process
> .ant-steps-item-container
> .ant-steps-item-icon {
  background: var(--primary-color-15) !important;
  border: 1px solid var(--primary-color) !important;
}

.menu-user
.ant-steps-item-process
> .ant-steps-item-container
> .ant-steps-item-icon
.ant-steps-icon {
  color: var(--primary-color) !important;
}

.menu-user
.ant-steps-item-process
> .ant-steps-item-container
> .ant-steps-item-content
> .ant-steps-item-title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: var(--primary-color) !important;
}

.menu-user
.ant-steps-item-process
> .ant-steps-item-container
> .ant-steps-item-tail::after,
.menu-user
.ant-steps-item-wait
> .ant-steps-item-container
> .ant-steps-item-tail::after {
  border: 1px solid #cbd4ed;
  height: 17px !important;
}

.menu-user
.ant-steps-item-finish
> .ant-steps-item-container
> .ant-steps-item-tail::after {
  height: 17px !important;
  background-color: #1ebe5e !important;
  border: 1px solid #1ebe5e;
}

.menu-user .ant-steps-vertical > .ant-steps-item {
  margin-top: 10px;
}

.menu-user .ant-steps-item-wait .ant-steps-item-icon {
  background: #ebeffc;
  border-color: transparent;
}

.menu-user .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: rgba(52, 68, 83, 0.5);
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.menu-user .ant-steps-item-finish .ant-steps-item-icon {
  background: #1ebe5e !important;
  border: 1px solid #1ebe5e !important;
}

.menu-user .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #ffffff !important;
}

/* Estilos Site */

.user-row {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 700px;
  margin-bottom: 50px !important;
  margin-top: 120px;
}

.user-row h3 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 36px;
  color: #344453;
  margin-bottom: 16px;
}

.user-row .input-box,
.box-revisao .dados .input-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px;
  width: 100%;
  height: auto;
  background: #ffffff;
  border: 1px solid rgba(52, 68, 83, 0.05);
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 15px;
}

.user-row .input-box p,
.box-revisao .dados .input-box p {
  margin-bottom: 0px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #344453;
}

.user-row .ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.box-revisao .dados .input .ant-input-affix-wrapper:focus {
  border-color: transparent !important;
}

.user-row .input-box .input input,
.box-revisao .dados .input-box .input input {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #485d73;
  width: 100%;
}

.user-row .ant-input-prefix {
  color: rgba(52, 68, 83, 0.5);
}

.user-row .inputs-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
}

.user-row .inputs-box .input-box {
  width: 48%;
}

/*Estilo Step 2 */

.user-row .boxes {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.user-row .boxes .box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  width: 224px;
  height: 140px;
  background: #ffffff;
  border: 1px solid #ebeffc;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 10px;
}

.user-row .boxes .box-selected {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  width: 224px;
  height: 140px;
  background: var(--primary-color);
  border: 0px solid #ebeffc;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 10px;
}

.user-row .boxes .box h4,
.user-row .boxes .box-selected h4 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #344453;
  margin-top: 10px;
}

.user-row .boxes .box p,
.user-row .boxes .box-selected p {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #485d73;
  margin-top: 0px;
}

.user-row .boxes .box-selected h4,
.user-row .boxes .box-selected p {
  color: #ffffff;
}

.user-row .boxes .box-check {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px;
  width: 224px;
  height: 76px;
  background: #ffffff;
  border: 1px solid #ebeffc !important;
  box-sizing: border-box;
  border-radius: 8px;
}

.user-row .boxes .box-check .box-text,
.user-row .boxes .box-check-selected .box-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 9px;
}

.user-row .boxes .box-check .box-text h6 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: rgba(52, 68, 83, 0.5);
  margin-bottom: 0px;
}

.user-row .boxes .box-check .box-text p {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #344453;
}

.user-row .boxes .box-check-selected {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  width: 224px;
  height: 76px;
  background: var(--primary-color);
  border-radius: 8px;
}

.user-row .boxes .box-check-selected .box-text h6 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 0px;
}

.user-row .boxes .box-check-selected .box-text p {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
}

.user-row h4 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #344453;
  margin-top: 15px;
}

.user-row .box-check {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.user-row .box-check p {
  margin-bottom: 0px;
  margin-left: 7px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #344453;
}

/*Estilo Step 5 */

.user-row .box-select {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 8px 12px;
  height: auto;
  background: #ffffff;
  border: 1px solid rgba(52, 68, 83, 0.05);
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
}

.user-row .box-select h5 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #344453;
}

/* Estilo step-1-6 */
.user-row .boxes-radios,
.box-revisao .dados .boxes-radios {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.user-row .boxes-radios .box-radios,
.box-revisao .dados .boxes-radios .box-radios {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.user-row .boxes-radios .box-radios .check-select,
.box-revisao .dados .boxes-radios .box-radios .check-select {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 5px;
}

.user-row .boxes-radios .box-radios .check-select h5,
.box-revisao .dados .boxes-radios .box-radios .check-select h5 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #344453;
  margin-left: 8px;
  margin-right: 8px;
}

.user-row .boxes-radios .box-radios .check-radios,
.box-revisao .dados .boxes-radios .box-radios .check-radios {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
}

.user-row .boxes-radios .box-radios .check-radios span,
.box-revisao .dados .boxes-radios .box-radios .check-radios span {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #6a7c96;
}

/* Estilo Step-7 */
#boxes-tags {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
}

#user-franquias-revisao h4 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #344453;
  margin-bottom: 5px;
}

#user-franquias-revisao p {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary-color);
  margin-bottom: 15px;
}

#user-controle {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 7px;
}

#user-controle .modulo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

#user-controle .modulo h5 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #344453;
  margin-bottom: 0px;
  margin-right: 7px;
}

#user-controle .modulo p {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #6a7c96;
}

.box-revisao .dados .input-box .input {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 12px;
  width: 100%;
  height: auto;
  background: #ffffff;
  border: 1px solid rgba(52, 68, 83, 0.05);
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 0px;
}

/* Franquia Step-1 */

h3#colaborador-title {
  text-align: center;
  width: 100%;
}

.user-row .input-colaborador {
  width: 100%;
  margin-bottom: 24px;
}

.user-row .input-colaborador input {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(52, 68, 83, 0.5);
}

.user-row .box-colaborador {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 15px;
  border: 1px solid #ebeffc;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 16px;
  background-color: #ffffff;
}

.user-row .box-colaborador .box-text,
.user-row .box-colaborador-selected .box-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0px;
}

.user-row .box-colaborador .box-text h3 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #1f2832;
  margin-bottom: 8px;
}

.user-row .box-colaborador .box-text .box-unidade h6 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #485d73;
  margin-bottom: 2px;
}

.user-row .box-colaborador .box-text .box-unidade h5 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #1f2832;
  margin-bottom: 0px;
}

.user-row .box-colaborador-selected {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 15px;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 16px;
  background: var(--primary-color);
}

.user-row .box-colaborador-selected .box-text h3 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
  margin-bottom: 8px;
}

.user-row .box-colaborador-selected .box-text .box-unidade h6 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 2px;
}

.user-row .box-colaborador-selected .box-text .box-unidade h5 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 0px;
}

/* Franquia Steps-2 */

.user-row .box-franquias-selected {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  width: 100%;
  height: auto;
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 16px;
}

.user-row .box-franquias-selected .franquia-info,
.user-row .box-franquias .franquia-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 18px;
}

.user-row .box-franquias .franquia-info h5 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #1f2832;
  margin-bottom: 16px;
}

.user-row .box-franquias-selected .franquia-info h5 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: var(--white-color);
  margin-bottom: 16px;
}

.user-row .box-franquias-selected .franquia-info .dados,
.user-row .box-franquias .franquia-info .dados {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.user-row .box-franquias-selected .franquia-info .dados .endereco,
.user-row .box-franquias .franquia-info .dados .endereco {
  margin-right: 35px;
}

.user-row .box-franquias-selected .franquia-info .dados .endereco h6,
.user-row .box-franquias .franquia-info .dados .endereco h6 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #485d73;
  margin-bottom: 0px;
}

.user-row .box-franquias-selected .franquia-info .dados .endereco h6 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: var(--white-color-50);
  margin-bottom: 0px;
}

.user-row .box-franquias .franquia-info .dados .endereco p {
  margin-bottom: 0px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #344453;
}

.user-row .box-franquias-selected .franquia-info .dados .endereco p {
  margin-bottom: 0px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: var(--white-color);
}

.user-row .box-franquias .franquia-info p {
  margin-bottom: 0px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: var(--primary-color);
}

.user-row .box-franquias-selected .franquia-info p {
  margin-bottom: 0px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: var(--white-color);
}

.user-row .box-franquias {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  width: 100%;
  height: auto;
  background: #fbfaff;
  border: 1px solid #ebeffc;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 16px;
}

#botao-voltar-revisao-user {
  position: absolute;
  width: 105px;
  height: 40px;
  left: 225px;
  top: 130px;
}

.box-group {
  display: flex !important;
  flex-direction: column !important;
  margin-bottom: 14 !important;
  font-family: Open Sans;
}

.box-group label {
  font-size: 16px !important;
  margin-bottom: 8px !important;
}

#input-usuario {
  min-width: 230px;
  max-width: 235px;
}

h3#title-senha {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 48px;
  color: #344453;
}

h6#sub-title-senha {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #92a9cb;
  margin-bottom: 25px;
}

#botoes-senha {
  justify-content: flex-end !important;
}

p#minimo {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: rgba(52, 68, 83, 0.5);
  margin-top: 0px;
  margin-bottom: 15px;
}

.box-title-senha {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.box-title-senha p {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: var(--primary-color);
}

#franquias-perfil {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 16px;
}

#franquias-perfil h4 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #344453;
  margin-bottom: 5px;
}

#franquias-perfil h5 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary-color);
}

.tags-perfil {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 25px;
}

.boxes-controle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  margin-top: 15px;
  width: 100%;
}

.box-controle h3 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #344453;
  margin-bottom: 3px;
  align-items: center;
}

.box-controle h6 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #6a7c96;
}

.password-requisitos {
  list-style-type: circle;
  font-family: 'Open Sans', sans-serif;
  color: var(--green-color);
  margin-left: 20px;
  margin-bottom: 20px;
  color: #1f2832;
}

.input-error {
  top: 56px;
  font-family: 'Open Sans';
  color: #e84118;
  font-style: normal;
  font-weight: 600;
}
