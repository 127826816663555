.upload-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
}

.upload-container__select-input {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: calc((100% / 2) - 16px);
}

.select-input__label,
.project-upload__label,
.upload-container__label {
  color: var(--dark-secondary);
  font-size: 12px;
}

.upload-container__project-upload {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.project-upload__upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 12px;
  width: 100%;
  cursor: pointer;
  border: 1px solid rgba(26, 77, 161, 0.15);
  border-radius: 8px;
  background-color: #F5F5F9;

  &:hover:not(:disabled) {
    border: 1px solid var(--primary-color);

    * {
      color: var(--primary-color);
    }
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.upload__upload-icon {
  color: var(--dark-secondary);

  * {
    height: 48px;
    width: 48px;
  }
}
