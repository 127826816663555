.cursos-wrapper__cursos-title-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cursos-wrapper__cursos-cards-wrapper {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 24px;
}

@media (min-width: 640px) {
  .cursos-wrapper__cursos-cards-wrapper {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 768px) {
  .cursos-wrapper__cursos-cards-wrapper {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .cursos-wrapper__cursos-cards-wrapper {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
