.conteudo-ministrado-form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  background-color: var(--white-color);

  padding: 16px;

  border-radius: 8px;

  width: 100%;
  height: fit-content;
}

.conteudo-ministrado-form-wrapper__text-field-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.text-field-container__label {
  font-size: 12px;
  line-height: 18px;
}

.text-field-container__input {
  background-color: var(--white-color-25) !important;

  border-radius: 8px !important;

  border: 1px solid var(--primary-color-15) !important;

  &:disabled {
    background-color: #f5f5f950 !important;

    color: #00000050 !important;
  }
}

.input__title {
  padding-left: 16px !important;
}

.text-field-container__select {
  padding: 14px 12px;
}

.conteudo-ministrado-wrapper__buttons {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
