.overlay-background {
  position: fixed;
  top: 0px;
  left: 0px;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.7);

  z-index: 1000;

  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-lancar-nota {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;

  padding: 16px 24px 16px 24px;

  width: 700px;
  height: auto;

  background: var(--white-color);

  box-shadow: 1px 3px 11px rgba(105, 105, 105, 0.15);

  border-radius: 8px;

  overflow-y: auto;
}

.modal-lancar-nota__titulo {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 36px;
  color: #344453;

  margin-bottom: 0px;
}

.modal-lancar-nota__text-field {
  display: flex;
  flex-direction: column;
  gap: 4px;

  width: 100%;
}

.text-field__label {
  font-family: Open Sans;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #344453;
}

.ant-input-number-input {
  border: 1px solid #ebeffc !important;

  padding: 8px 16px !important;

  height: auto !important;
}

.modal-lancar-nota__buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;

  width: 100%;
}

.modal-lancar-nota__buttons button {
  margin: 0 !important;
}
