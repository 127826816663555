#content-skills {
  background-color: white;
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-grow: 1;
  width: 100%;
  padding: 10px;
}

#container-header-skill {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#content-skills-dados-aluno {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
}

#content-skills-questionario-informacao {
  margin-top: 10px;
}

#content-skills-questionario-informacao .mensagem-sobre-skill {
  font-size: 20px;
}

.texto-destacado {
  font-weight: bold;
  color: var(--primary-color);
  text-transform: lowercase;
}

.separator {
  border: 1px solid rgb(238, 235, 235);
  height: 40px;
}
#container-dados-aluno h1 {
  font-size: 20px;
}

#container-dados-aluno h6 {
  font-size: 12px;
}

.container-item-skill {
  padding-bottom: 10px;
}

.separator-horizontal {
  width: 100%;
  border-top: 1px solid rgb(238, 235, 235);
  padding: 5px 0px 5px 0px;
}

.container-alternativas {
  flex: 1;
  flex-direction: row;
  padding-bottom: 10px;
}

.skill-descricao {
  /* font-weight: bold; */
}
