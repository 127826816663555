.box-eye {
  display: inline-block;
  justify-content: center;
  margin: auto auto auto 5px;
  cursor: pointer;
}

.open-eye {
  position: relative;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 80% 0;
  transform: rotate(45deg);
  background-color: #000;
  opacity: 0.7;
  margin: 0 auto;
}

.retina {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 9px;
  height: 9px;
  margin: auto;
  border-radius: 100%;
  background-color: #000;
  border: 2px solid #fff;
}

.closed-eye {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 3px;
  height: 25px;
  margin: auto;
  transform: rotate(90deg);
  background-color: #000;
  border: 1px solid #fff;
}
