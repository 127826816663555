.status-matricula {
  display: flex;
  align-items: center;
  gap: 4px;

  p {
    font-size: 14px;
    line-height: 20px;
    color: var(--dark-secondary);
  }
}

.status-matricula__dot-green {
  background-color: var(--green-color);
}

.status-matricula__dot-orange {
  background-color: var(--toast);
}

.status-matricula__dot-green,
.status-matricula__dot-orange {
  width: 8px;
  height: 8px;

  border-radius: 100%;
}
