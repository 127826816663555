@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

@font-face {
  font-family: 'Roboto-Regular';
  src: url(../fonts/Roboto-Regular.ttf);
}

:root {
  --primary-color: #1a4da1;
  --secondary-color: #92a9cb;
  --silver-color: #cbd4ed;
  --silver-base: #ebeffc;
  --white-color: #ffffff;
  --background-color: #fafafc;
  --green-color: #b0cb51;
  --azul-primario: #0c306c;
  --blue-gray: #b0bec5;
  --dark: #344453;
  --dark-secondary: #6a7c96;
  --happy-money: #fcb017;
  --happy-code: #b1cb4e;
  --happy-speech: #dd3736;
  --happy-english: #f37021;
  --blue-gray: #cfd8dc;
  --blue-light: #1f61cc;
  --toast: #ecd60f;
  --gray-light: #485d73;
  --link: #2979ff;
  --trilha-digital: #00bcd4;
  --trilha-analitica: #9c27b0;
  --trilha-socio: #e91e63;
  --trilha-metodologica: #ff9800;
  --orange-base: #fbaf1f;
  --check: #1ebe5e;
  --dark-green: #627b08;
  // --toast:#F59300;
  --purple-theme: #B576DD;
  --purple-theme-light: #BF88E5;
  --purple-theme-hover : #8B27BB;
  --purple-theme-extra-light: #E3CEF1;
  --blue-theme: #142a52;  
  --blue-theme-light: #3f5b8b;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
b,
u,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

body {
  width: 100vw;
  height: 100vh;
  font-family: 'Open Sans', sans-serif;
}

.container {
  background-color: var(--background-color);
  display: flex;
  flex-direction: row;
  overflow: scroll;
  // height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

/* Link */
.link {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  color: var(--link);
  margin-left: 15px !important;
}

/* Flex */
.d-flex-column {
  display: flex;
  flex-direction: column;
}

.d-flex-row {
  display: flex;
  flex-direction: row;
}

.j-start-a-center {
  justify-content: flex-start;
  align-items: center;
}

.j-start-a-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.j-end-a-center {
  justify-content: flex-end;
  align-items: center;
}

.j-end-a-end {
  justify-content: flex-end;
  align-items: flex-end;
}

.j-center-a-center {
  justify-content: center;
  align-items: center;
}

.j-between-a-center {
  justify-content: space-between;
  align-items: center;
}

.j-evenly-a-center {
  justify-content: space-evenly;
  align-items: center;
}

.j-between-a-start {
  justify-content: space-between;
  align-items: flex-start;
}

.j-between-a-center {
  justify-content: space-between;
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

/* Margin */

.m-auto {
  margin: auto !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

/*border */
.br-1 {
  border-right: 1px solid var(--blue-gray);
}

.bl-1 {
  border-left: 1px solid var(--blue-gray);
}

/* width */

.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-33 {
  width: 33%;
}

.w-40 {
  width: 40%;
}

.w-49 {
  width: 49%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.w-100 {
  width: 100%;
}

/* Line */

.line {
  border: 1px solid var(--blue-gray);
  width: 100%;
  margin: 20px 0px;
}

.line-wm {
  border: 1px solid var(--blue-gray);
  width: 100%;
  margin: 0px 0px 0px 0px;
}

.nowrap {
  white-space: nowrap;
}

/* Buttons */

.button-primary {
  background: var(--primary-color) !important;

  border-radius: 8px !important;
  border: 0px !important;

  color: #ffffff !important;

  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 24px !important;

  max-width: 100% !important;

  display: flex !important;
  justify-content: center;
  align-items: center;

  padding: 8px 24px !important;

  &:hover {
    background-color: var(--blue-dark) !important;
  }

  &:disabled {
    background-color: var(--cinza-color-20) !important;
    color: #31313148 !important;
  }
}

.button-blue {
  background: var(--silver-base) !important;
  border-radius: 4px !important;
  color: var(--primary-color) !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  text-align: center !important;
  height: 40px !important;
  max-width: 100% !important;
  width: auto;
  border: 0px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 25px 20px;
  cursor: pointer;
}

.button-disable {
  background: var(--blue-gray) !important;
  border-radius: 4px !important;
  color: var(--white-color) !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  text-align: center !important;
  height: 40px !important;
  max-width: 100% !important;
  width: auto;
  border: 0px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 25px 20px;
  cursor: pointer;
}

.button-inative {
  background: var(--silver-base) !important;
  border-radius: 4px !important;
  color: var(--secondary-color) !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  text-align: center !important;
  height: 40px !important;
  max-width: 100% !important;
  width: auto;
  border: 0px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 25px 20px;
  cursor: pointer;
}

.button-white {
  background: var(--white-color) !important;
  border-radius: 4px !important;
  color: var(--primary-color) !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  text-align: center !important;
  height: 40px !important;
  max-width: 100% !important;
  width: auto;
  border: 0px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 25px 20px;
  cursor: pointer;
}

/* Input style */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s !important;
  -webkit-text-fill-color: #344453 !important;
}

input:focus {
  outline-offset: 0px !important;
  outline: none !important;
}

::placeholder {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(52, 68, 83, 0.5);
}

/* Antd */

.ant-select-selection-item {
  color: rgba(52, 68, 83, 0.5) !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none !important;
  background-color: transparent !important;
}

.ant-pagination-item {
  border: 0px solid var(--orange-base) !important;
  border-radius: 16px !important;
  background-color: transparent !important;

  a {
    padding: 0px !important;
    margin-left: 0px !important;
    font-size: 14px !important;
    line-height: 30px !important;
    color: var(--orange-base) !important;
  }
}

.ant-pagination-item-active {
  border: 1px solid var(--orange-base) !important;
  border-radius: 16px !important;

  a {
    color: var(--orange-base) !important;
  }
}

.ant-pagination-prev {
  .ant-pagination-item-link {
    border: 1px solid var(--orange-base) !important;
    border-radius: 16px !important;
    background-color: var(--orange-base) !important;
    color: var(--white-color);
  }
}

.ant-pagination-disabled {
  .ant-pagination-item-link {
    color: var(--white-color) !important;
  }
}

.ant-pagination-next {
  .ant-pagination-item-link {
    border: 1px solid var(--orange-base) !important;
    border-radius: 16px !important;
    background-color: var(--orange-base) !important;
    color: var(--white-color);
  }
}

.ant-input:focus {
  border-right-width: 0px !important;
  outline: none !important;
  outline-offset: 0px !important;
}

/* ul, li */
ol,
ul,
dl {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.text-align {
  text-align: center;
}
