.result {
  background-color: rgba(203, 212, 237, 0.2);
  color: var(--dark);
  padding: 5px 15px;

  p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: var(--dark);
    margin-bottom: 0px;

    span {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      color: var(--primary-color);
    }
  }
}

.busca-tags {
  p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: rgba(52, 68, 83, 0.5);
    margin-bottom: 10px;
  }
}

.dashboard {
  .coin-box {
    background-color: var(--primary-color);
    padding: 15px 30px;
    border-radius: 8px;

    img {
      width: 70px;
      height: auto;
      margin-right: 20px;
    }

    h6 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: var(--white-color);
      margin-bottom: 10px !important;

      span {
        font-weight: bold;
      }
    }

    p {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 0px;
      color: var(--white-color);
    }
  }

  img.foto-aula {
    width: 100%;
    height: auto;

    border-radius: 8px;

    overflow: hidden;
  }

  img.avatar-aula {
    width: 40px;
    height: auto;
    margin-right: 5px;
  }

  h3 {
    span {
      color: rgba(52, 68, 83, 0.5);
    }
  }

  .box-info-aula {
    padding: 20px 0px 20px 0px;
  }

  .box-trilha {
    padding: 30px 0px 30px 30px;

    h2 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: var(--blue-gray);

      span {
        color: var(--trilha-digital);
      }
    }
  }

  .circle-gray {
    background-color: var(--blue-gray);
    width: 40px;
    height: 35px;
    border-radius: 20px;
    margin-left: 10px;
  }
}

@media (min-width: 1201px) and (max-width: 1300px) {
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .dashboard .box-info-aula {
    flex-direction: column;

    .carga,
    .aula-coins,
    .minister {
      width: 100%;
      padding-left: 0px !important;
      border-right: 0px !important;
      margin-bottom: 10px;
    }
  }

  .box-progress {
    flex-direction: column;
    align-items: flex-start;
  }

  #button-progress {
    margin-top: 10px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .dashboard .box-info-aula {
    flex-direction: column;

    .carga,
    .aula-coins,
    .minister {
      width: 100%;
      padding-left: 0px !important;
      border-right: 0px !important;
      margin-bottom: 10px;
    }
  }

  .box-progress {
    flex-direction: column;
    align-items: flex-start;
  }

  #button-progress {
    margin-top: 10px;
  }
}

@media screen and (max-width: 767px) {
  #box-aula {
    flex-direction: column;
  }

  #box-foto-aula {
    width: 100% !important;
  }

  .box-trilha {
    width: 100%;
    padding: 30px 0px !important;
  }
}
