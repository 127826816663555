.instrutor {
  /* position: relative; */
  top: 0px;
  width: 100%;
  max-height: 100vh;
  padding: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* background-color: var(--white-color); */
}

.instrutor img {
  max-height: 100vh;
  margin-top: 0px;
}

.instrutor img.img-left {
  position: fixed;
  left: 25px !important;
}

.instrutor .fondo-rigth {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 50%;
  position: fixed;
  right: 0;
}

.instrutor .fondo-blue {
  max-height: 100vh;
  height: 100vh;
  min-width: 200px;
  width: 200px;
  background-color: #0d57a2;
}

.dashboard-instrutor {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  margin: 0 auto;
  width: 100%;
  max-height: 100vh;
  background-color: transparent;
  padding: 50px 75px 0px 190px;
}

.dashboard-instrutor .head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.dashboard-instrutor .head .info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  width: 75%;
  height: 150px;
  background: #ffffff;
  border-radius: 8px;
}

.dashboard-instrutor .head .info h4 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 36px;
  color: #344453;
  margin-bottom: 10px;
}

.dashboard-instrutor .head .info h6 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(52, 68, 83, 0.5);
  margin-bottom: 5px;
}

.dashboard-instrutor .head .info p {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #344453;
}

.dashboard-instrutor .head .jornada {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  width: 23%;
  height: 150px;
  background: #1a4da1;
  border-radius: 8px;
}

.dashboard-instrutor .head .jornada h6 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: var(--white-color);
}

.dashboard-instrutor .head .jornada p {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: var(--white-color);
}

.dashboard-instrutor .head .prodata,
.dashboard-instrutor .body-data .left .prodata {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  width: 30%;
  height: 245px;
  background: #ffffff;
  border-radius: 8px;
  position: relative;
}

.dashboard-instrutor .head .data,
.dashboard-instrutor .body-data .left .prodata .data {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0px 20px;
  position: absolute;
  top: 50px;
  left: -60px;
}

.dashboard-instrutor .head .prodata h4,
.dashboard-instrutor .body-data .left .prodata h4 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #344453;
}

.dashboard-instrutor .head .prodata h6 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #485d73;
}

.dashboard-instrutor .head .prodata .dot-green {
  width: 10px;
  height: 10px;
  background: #b0cb51;
  border-radius: 8px;
  display: inline-block;
  margin-right: 10px;
}

.dashboard-instrutor .head .prodata .dot-blue {
  width: 10px;
  height: 10px;
  background: var(--primary-color);
  border-radius: 8px;
  display: inline-block;
  margin-right: 10px;
}

.dashboard-instrutor .head .prodata .dot-orange {
  width: 10px;
  height: 10px;
  background: var(--orange-color);
  border-radius: 8px;
  display: inline-block;
  margin-right: 10px;
}

.dashboard-instrutor .body-data {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.dashboard-instrutor .body-data .left,
.dashboard-instrutor .body-data .right {
  width: 48%;
  height: auto;
  padding: 0px;
  border-radius: 8px;
}

.dashboard-instrutor .body-data .right .gestao,
.dashboard-instrutor .body-data .right .turmas {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  height: auto;
  background: var(--white-color);
  border-radius: 8px;
  margin-bottom: 25px;
}

.dashboard-instrutor .body-data .right .gestao .title,
.dashboard-instrutor .body-data .right .turmas .title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  width: 100%;
}

.dashboard-instrutor .body-data .right .gestao .title h4 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 36px;
  color: #344453;
  margin-bottom: 0px;
}

.dashboard-instrutor .body-data .right .gestao .info {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  width: 100%;
  height: 40px;
  background: rgba(236, 214, 15, 0.1);
  box-shadow: inset 3px 0px 0px #ecd60f;
}

.dashboard-instrutor .body-data .right .gestao .info p {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #485d73;
  margin-bottom: 0px;
  margin-left: 10px;
}

.dashboard-instrutor .body-data .right .turmas .title h4 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #6a7c96;
}

.dashboard-instrutor .body-data .right .turmas .title h5 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--primary-color);
}

.dashboard-instrutor .body-data .right .turmas h3 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 36px;
  color: #344453;
  padding-left: 25px;
  margin-top: -20px;
}

#container-gestao-aulas .cajas {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 25px;
  width: 100%;
  height: auto;
  border-radius: 8px;
}

#container-gestao-aulas .cajas .box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 48%;
  height: auto;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #ebeffc;
  position: relative;
  margin-bottom: 10px;
}

#container-gestao-aulas .cajas .box .box-body {
  padding: 25px;
}

#container-gestao-aulas .cajas .box .box-body h4 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 2px;
}

#container-gestao-aulas .cajas .box .box-body p {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: rgba(52, 68, 83, 0.5);
  margin-bottom: 5px;
}

#container-gestao-aulas .cajas .box .box-body h6 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #1ebe5e;
  margin-top: 10px;
}

#container-gestao-aulas .cajas .box .box-body .dot-green {
  width: 8px;
  height: 8px;
  background: #1ebe5e;
  border-radius: 4px;
  display: inline-block;
  margin-right: 5px;
}

#container-gestao-aulas .cajas .box .box-body h5 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: rgba(52, 68, 83, 0.5);
  margin-bottom: 2px;
}

#container-gestao-aulas .cajas .box .box-body p.color-black {
  color: #485d73;
  font-weight: 600;
}

#container-gestao-aulas .cajas .box .box-body .datas {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0px 10px 0px;
  overflow-x: scroll;
}

#container-gestao-aulas .cajas .box .box-body .datas .data {
  padding: 8px;
  width: 115px;
  height: 35px;
  background: rgba(176, 203, 81, 0.15);
  border-radius: 4px;
  margin-right: 10px;
}

#container-gestao-aulas .cajas .box .box-body .datas .data h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;

  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #627b08;
}

#container-gestao-aulas .cajas .box .detalhes {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  width: 100%;
  height: 32px;
  background: #ebeffc;
  border-radius: 0px 0px 8px 8px;
}

#container-gestao-aulas .cajas .box .detalhes p {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: var(--primary-color);
  margin-bottom: 0px;
}

#container-gestao-aulas .cajas .box .pon {
  position: absolute;
  top: 20px;
  right: 20px;
}

.dashboard-instrutor .body-data .left .pesquisa {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  width: 100%;
  height: auto;
  background: #ffffff;
  border-radius: 8px;
}

.dashboard-instrutor .body-data .left .pesquisa h4 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #344453;
  margin-bottom: 20px;
}

.dashboard-instrutor .body-data .left .calendar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  height: auto;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 25px;
}

.dashboard-instrutor .body-data .left .calendar .cal-head {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px;
}

.dashboard-instrutor .body-data .left .calendar .cal-head h5 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #92a9cb;
}

.dashboard-instrutor .body-data .left .calendar .cal-body,
.dashboard-instrutor .body-data .right .cal-body {
  padding: 24px;
  width: 100%;
  height: auto;
  background: #ffffff;
  border-top: 1px solid #ebeffc;
}

.dashboard-instrutor .body-data .left .calendar .cal-body .title,
.dashboard-instrutor .body-data .right .cal-body .title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.dashboard-instrutor .body-data .left .calendar .cal-body .title h3 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #485d73;
}

.dashboard-instrutor .body-data .left .calendar .cal-body .title h2 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary-color);
}

.dashboard-instrutor .body-data .left .calendar .cal-body .cards,
.dashboard-instrutor .body-data .right .cal-body .cards {
  height: 500px;
  overflow: scroll;
}

.dashboard-instrutor .body-data .left .calendar .cal-body .cards .card,
.dashboard-instrutor .body-data .right .cal-body .cards .card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
  width: 100%;
}

.dashboard-instrutor .body-data .left .calendar .cal-body .cards .card h6,
.dashboard-instrutor .body-data .right .cal-body .cards .card h6 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-transform: capitalize;
  color: rgba(52, 68, 83, 0.5);
  margin-right: 20px;
}

.dashboard-instrutor .body-data .left .calendar .cal-body .cards .card .aula,
.dashboard-instrutor .body-data .right .cal-body .cards .card .aula {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 0px 10px 20px;
  width: 100%;
  height: auto;
  background: #ffffff;
  border: 1px solid #ebeffc;
  box-sizing: border-box;
  border-radius: 8px;
  position: relative;
}

.dashboard-instrutor
  .body-data
  .left
  .calendar
  .cal-body
  .cards
  .card
  .aula
  .inset-red,
.dashboard-instrutor .body-data .right .cal-body .cards .card .aula .inset-red {
  height: 100%;
  width: 8px;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #dc3735;
}

.dashboard-instrutor .body-data .left .calendar .cal-body .cards .card .aula h3,
.dashboard-instrutor .body-data .right .cal-body .cards .card .aula h3 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #344453;
  margin-bottom: 10px;
}

.dashboard-instrutor
  .body-data
  .left
  .calendar
  .cal-body
  .cards
  .card
  .aula
  .inset-green,
.dashboard-instrutor
  .body-data
  .right
  .cal-body
  .cards
  .card
  .aula
  .inset-green {
  height: 100%;
  width: 8px;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #b0cb51;
}

.dashboard-instrutor
  .body-data
  .left
  .calendar
  .cal-body
  .cards
  .card
  .aula
  .inset-amarelo,
.dashboard-instrutor
  .body-data
  .right
  .cal-body
  .cards
  .card
  .aula
  .inset-amarelo {
  height: 100%;
  width: 8px;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #fbaf1f;
}

.dashboard-instrutor .body-data .left .calendar .cal-head .days {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.dashboard-instrutor .body-data .left .calendar .cal-head .days .day {
  width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 0px;
}

.dashboard-instrutor .body-data .left .calendar .cal-head .days .day h4 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  color: #485d73;
  margin-bottom: 0px;
  margin-right: 5px;
}

.dashboard-instrutor .body-data .left .calendar .cal-head .days .day h3 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  color: #92a9cb;
  margin-bottom: 0px;
}

.dashboard-instrutor .body-data .left .calendar .cal-head .days .day-selected {
  width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 0px;
  background-color: var(--primary-color);
  border-radius: 8px;
}

.dashboard-instrutor
  .body-data
  .left
  .calendar
  .cal-head
  .days
  .day-selected
  h4 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  color: var(--white-color);
  margin-bottom: 0px;
  margin-right: 5px;
}

.dashboard-instrutor
  .body-data
  .left
  .calendar
  .cal-head
  .days
  .day-selected
  h3 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  color: var(--white-color);
  margin-bottom: 0px;
}

.dashboard-instrutor .head .data-line,
.dashboard-instrutor .body-data .left .data .data-line {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px;
  width: 68%;
  height: 245px;
  background: #ffffff;
  border-radius: 8px;
}

.dashboard-instrutor .head .data-line h4,
.dashboard-instrutor .body-data .left .data .data-line h4 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 0 auto;
  color: #344453;
}

.dashboard-instrutor .head .data-line .cursos,
.dashboard-instrutor .body-data .left .data .data-line .cursos {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.dashboard-instrutor .head .data-line .cursos .dot-green,
.dashboard-instrutor .body-data .left .data .data-line .dot-green {
  width: 10px;
  height: 10px;
  background: #b0cb51;
  border-radius: 8px;
  display: inline-block;
  margin-right: 10px;
}

.dashboard-instrutor .head .data-line .cursos .dot-blue,
.dashboard-instrutor .body-data .left .data .data-line .dot-blue {
  width: 10px;
  height: 10px;
  background: var(--primary-color);
  border-radius: 8px;
  display: inline-block;
  margin-right: 10px;
}

.dashboard-instrutor .head .data-line .cursos .dot-orange,
.dashboard-instrutor .body-data .left .data .data-line .dot-orange {
  width: 10px;
  height: 10px;
  background: var(--orange-color);
  border-radius: 8px;
  display: inline-block;
  margin-right: 10px;
}

.dashboard-instrutor .head .data-line .cursos h6,
.dashboard-instrutor .body-data .left .data .data-line h6 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #485d73;
  margin-right: 10px;
}

.dashboard-instrutor .head .data-line .lines {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 20px;
}

.dashboard-instrutor .head .data-line .lines h5 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  text-transform: capitalize;
  color: #344453;
}

.dashboard-instrutor .head .data-line .lines .line {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 150px;
}

/* Dash Franquia */

.dashboard-instrutor .body-data .left .boxes-dash {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.dashboard-instrutor .body-data .left .boxes-dash .box-blue {
  background: #1a4da1;
  height: 100px;
  border-radius: 8px;
  background-image: url('/src/assets/img/dashboard/mask-blue.png');
  background-repeat: none;
  background-size: cover;
  width: 24%;
  padding: 24px;
}

.dashboard-instrutor .body-data .left .boxes-dash .box-amarelo {
  background: #fbaf1f;
  height: 100px;
  border-radius: 8px;
  background-image: url('/src/assets/img/dashboard/mask-amarelo.png');
  background-repeat: none;
  background-size: cover;
  width: 24%;
  padding: 24px;
}

.dashboard-instrutor .body-data .left .boxes-dash .box-green {
  background: #b0cb51;
  height: 100px;
  border-radius: 8px;
  background-image: url('/src/assets/img/dashboard/mask-green.png');
  background-repeat: none;
  background-size: cover;
  width: 24%;
  padding: 24px;
}

.dashboard-instrutor .body-data .left .boxes-dash .box-red {
  background: #dc3735;
  height: 100px;
  border-radius: 8px;
  background-image: url('/src/assets/img/dashboard/mask-red.png');
  background-repeat: none;
  background-size: cover;
  width: 24%;
  padding: 24px;
}

.dashboard-instrutor .body-data .left .boxes-dash .box-blue h6,
.dashboard-instrutor .body-data .left .boxes-dash .box-amarelo h6,
.dashboard-instrutor .body-data .left .boxes-dash .box-green h6,
.dashboard-instrutor .body-data .left .boxes-dash .box-red h6 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.dashboard-instrutor .body-data .left .boxes-dash .box-blue h4,
.dashboard-instrutor .body-data .left .boxes-dash .box-amarelo h4,
.dashboard-instrutor .body-data .left .boxes-dash .box-green h4,
.dashboard-instrutor .body-data .left .boxes-dash .box-red h4 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 0.5px;
  color: #ffffff;
}

.dashboard-instrutor .body-data .left .data {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.dashboard-instrutor .body-data .left .listagem {
  width: 100%;
  height: auto;
  border-radius: 8px;
  background-color: var(--white-color);
  overflow: scroll;
  max-height: 550px;
  margin-bottom: 0px;
}

.dashboard-instrutor .body-data .left .listagem .title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px 20px;
}

.dashboard-instrutor .body-data .left .listagem .title h4 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #344453;
}

.dashboard-instrutor .body-data .right .cal-body .title h3 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #344453;
}

.dashboard-instrutor .body-data .right .cal-body .cards .card .aulas {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.dashboard-instrutor .body-data .right .cal-body .cards .card .aulas .aula {
  margin-bottom: 10px;
}

.dashboard-instrutor .body-data .right .cal-body .cards .card .aulas .aula p {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #485d73;
  margin-bottom: 0px;
  margin-top: 5px;
}

#instrutor .turmas {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  height: auto;
  background: var(--white-color);
  border-radius: 8px;
  margin-bottom: 25px;
}

#instrutor .turmas .title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  width: 100%;
}

#instrutor .turmas .title h2 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 26px;
  color: #6a7c96 !important;
}

#instrutor .turmas .cajas {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 25px;
  width: 100%;
  height: auto;
  border-radius: 8px;
}

#instrutor .turmas .cajas .box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 48%;
  height: auto;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #ebeffc;
  position: relative;
  margin-bottom: 10px;
}

#instrutor .turmas .cajas .box .box-body {
  padding: 25px;
}

#instrutor .turmas .cajas .box .box-body h4 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 2px;
}

#instrutor .turmas .cajas .box .box-body p {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: rgba(52, 68, 83, 0.5);
  margin-bottom: 5px;
}

#instrutor .turmas .cajas .box .box-body h6 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #1ebe5e;
  margin-top: 10px;
}

#instrutor .turmas .cajas .box .box-body .dot-green {
  width: 8px;
  height: 8px;
  background: #1ebe5e;
  border-radius: 4px;
  display: inline-block;
  margin-right: 5px;
}

#instrutor .turmas .cajas .box .box-body h5 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: rgba(52, 68, 83, 0.5);
  margin-bottom: 2px;
}

#instrutor .turmas .cajas .box .box-body p.color-black {
  color: #485d73;
  font-weight: 600;
}

#instrutor .turmas .cajas .box .box-body .datas {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0px 10px 0px;
  overflow-x: scroll;
}

#instrutor .turmas .cajas .box .box-body .datas .data {
  padding: 8px;
  width: 115px;
  height: 35px;
  background: rgba(176, 203, 81, 0.15);
  border-radius: 4px;
  margin-right: 10px;
}

#instrutor .turmas .cajas .box .box-body .datas .data h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;

  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #627b08;
}

#instrutor .turmas .cajas .box .detalhes {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  width: 100%;
  height: 32px;
  background: #ebeffc;
  border-radius: 0px 0px 8px 8px;
}

#instrutor .turmas .cajas .box .detalhes p {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: var(--primary-color);
  margin-bottom: 0px;
}

#instrutor .turmas .cajas .box .pon {
  position: absolute;
  top: 20px;
  right: 20px;
}

#instrutor .turmas h3 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 36px;
  color: #344453;
  padding-left: 25px;
}

#instrutor .turmas .title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  width: 100%;
  padding-bottom: 0px;
}

#aviso-qtd-aulas-container {
  background-color: #faf6e3;
  padding: 10px;
  border-left: 3px solid #ecd612;
}

#aviso-qtd-aulas-container span {
  margin-left: 10px;
  color: #495e73;
  font-size: 12px;
  font-weight: 600;
}

#instrutor #container-gestao-aulas .titulo-gestao-aulas {
  padding: 20px;
}

#instrutor #container-gestao-aulas {
  background-color: var(--white-color);
  width: 49%;
  border-radius: 8px;
  min-height: 375px;
  height: auto;
  /* padding: 20px; */
  position: relative;
}
