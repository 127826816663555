.box-news {
  width: 32%;

  img {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }

  h3 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: var(--dark);
    margin-bottom: 25px !important;
  }

  p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    color: var(--dark);
    margin-bottom: 30px;
  }

  .enlace {
    width: 100%;
    text-align: right;

    a {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold !important;
      font-size: 14px !important;
      line-height: 24px !important;
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .box-news {
    width: 48%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .box-news {
    width: 100%;
    margin-bottom: 20px;
  }
}
