#container-dashboard {
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
}

.dashboard {
  background-color: transparent;
  width: 100%;
  min-height: 100vh;

  .row {
    background-color: transparent;

    .header {
      background-color: var(--white-color);
      padding: 20px;
      justify-content: space-between;
      align-items: center;

      .box-info {
        margin-left: 15px;
        justify-content: flex-start;
        align-items: flex-start;

        h6 {
          font-family: Open Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 24px;
          text-transform: uppercase;
          color: var(--dark);
          margin-bottom: 0px;
        }

        h2 {
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 26px;
          line-height: 36px;
          color: var(--dark);
          margin-bottom: 5px;
        }

        .medal {
          margin-top: 5px;

          .position {
            color: var(--white-color);
            background-color: var(--azul-primario);
            text-transform: uppercase;
            padding: 5px 10px;
            border-radius: 10px;
            font-size: 10px;
            line-height: 20px;
            margin-left: 5px;
          }
        }
      }

      .coins {
        h6 {
          font-family: Open Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 24px;
          text-transform: uppercase;
          color: var(--happy-money);
        }

        h1 {
          font-family: Open Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 25px;
          line-height: 24px;
          color: var(--dark);
          margin-left: 5px;
          margin-top: 0px;
          margin-bottom: 0px;
        }

        p {
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 24px;
          color: var(--dark);

          a {
            color: var(--link);
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 24px;
            margin-left: 0px !important;
          }
        }
      }
    }
  }

  h1 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 36px;
    color: var(--dark);
    margin-top: 34px;
    margin-bottom: 10px;
  }

  #aula {
    width: 45px;
    height: auto;
    margin-right: 15px;
  }

  .green-box {
    padding: 5px 25px;
    width: 24%;
    height: 92px;
    background: var(--happy-code);
    border-radius: 8px;

    h2 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 32px;
      color: var(--white-color);
      margin-bottom: 5px;
    }

    h5 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
      color: var(--white-color);
    }
  }

  .yellow-box {
    padding: 5px 25px;
    width: 24%;
    height: 92px;
    background: var(--happy-money);
    border-radius: 8px;

    h2 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 32px;
      color: var(--white-color);
      margin-bottom: 5px;
    }

    h5 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
      color: var(--white-color);
    }
  }

  .red-box {
    padding: 5px 25px;
    width: 24%;
    height: 92px;
    background: var(--happy-speech);
    border-radius: 8px;

    h2 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 32px;
      color: var(--white-color);
      margin-bottom: 5px;
    }

    h5 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
      color: var(--white-color);
    }
  }

  .blue-box {
    padding: 5px 25px;
    width: 24%;
    height: 92px;
    background: var(--azul-primario);
    border-radius: 8px;

    h2 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 32px;
      color: var(--white-color);
      margin-bottom: 5px;
    }

    h5 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
      color: var(--white-color);
    }
  }

  .white-box {
    background-color: var(--white-color);
    width: 49%;
    border-radius: 8px;
    min-height: 375px;
    height: auto;
    padding: 20px;
    position: relative;

    h1 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 26px;
      line-height: 36px;
      color: var(--dark);
      margin-top: 0px;
      margin-bottom: 0px;
    }

    p {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: var(--secondary-color);
      margin-left: 3px;
      margin-bottom: 0px !important;
    }

    h4 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      color: var(--dark);
    }

    .horario {
      width: auto;
      height: auto;
      padding: 8px 10px;
      background: rgba(203, 212, 237, 0.2);
      border-radius: 10px;
      margin-right: 10px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: var(--blue-light);

      span {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: var(--primary-color);
        margin-right: 10px;
      }
    }

    img.mentor {
      width: 35px;
      height: auto;
      margin-right: 15px;
    }

    h6 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 24px;
      color: var(--dark);
    }

    .lancamento {
      border-left: 3px solid var(--toast);
      width: calc(100% + 32px);
      margin-left: -20px;
      background-color: rgba(236, 214, 15, 0.1);
      padding: 10px 0px 10px 5px;

      span {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: var(--gray-light);
        margin-left: 5px;
      }
    }

    h2 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      color: var(--dark);
    }

    #aulas {
      color: var(--secondary-color);
    }

    .mentoria-text {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 24px;
      color: var(--secondary-color);
    }
  }

  a {
    margin-left: 0px;
    font-size: 18px;
    line-height: 25px;
  }

  .fav-title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 36px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: var(--dark);
    margin-left: 15px;
  }

  .input-box {
    background: #ffffff;
    border: 1px solid rgba(52, 68, 83, 0.05);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px 10px;
    border-radius: 5px;

    input {
      width: 100%;
      border: 0px solid;
      padding: 0px 10px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: rgba(52, 68, 83, 0.5);
      outline: none;
    }

    input:focus {
      border-right-width: 0px !important;
      outline: none !important;
      box-shadow: 0 0 0 0px rgb(24 144 255 / 20%);
    }
  }

  .select-box {
    background: #cbd4ed33;
    border-radius: 4px;
    min-width: auto;
    width: 12%;
    min-height: 46px;
    margin-left: 10px;
    padding: 0px 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: rgba(52, 68, 83, 0.5);

    .select-fav {
      border: none !important;
      margin-left: 5px;
    }
  }

  .menu {
    background-color: var(--white-color);
    margin-bottom: 0px !important;
    border-radius: 8px 8px 0px 0px;

    .item {
      width: 150px;
      height: 40px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: var(--gray-light);
    }

    .active {
      border-bottom: 1px solid var(--primary-color);
      color: var(--primary-color);
    }
  }

  .block-m {
    border-radius: 0px 0px 8px 8px !important;
  }

  .block-digital {
    padding: 15px;
    background-color: var(--white-color);
    border-radius: 8px 8px 8px 8px;

    img {
      margin-right: 10px;
    }

    h2 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: var(--blue-gray);

      span {
        color: var(--trilha-digital);
      }
    }

    p {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 0px !important;
      color: var(--secondary-color);
    }

    .coin-trilha {
      width: 16px;
      height: 16px;
      margin-left: 10px;
    }

    h5 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: var(--dark);
    }
  }

  .block-analitica {
    padding: 15px;
    background-color: var(--white-color);
    border-radius: 8px 8px 8px 8px;

    img {
      margin-right: 10px;
    }

    h2 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: var(--blue-gray);

      span {
        color: var(--trilha-analitica);
      }
    }

    p {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 0px !important;
      color: var(--secondary-color);
    }

    .coin-trilha {
      width: 16px;
      height: 16px;
      margin-left: 10px;
    }

    h5 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: var(--dark);
    }
  }

  .block-socio {
    padding: 15px;
    background-color: var(--white-color);
    border-radius: 8px 8px 8px 8px;

    img {
      margin-right: 10px;
    }

    h2 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: var(--blue-gray);

      span {
        color: var(--trilha-socio);
      }
    }

    p {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 0px !important;
      color: var(--secondary-color);
    }

    .coin-trilha {
      width: 16px;
      height: 16px;
      margin-left: 10px;
    }

    h5 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: var(--dark);
    }
  }

  .block-metodologicas {
    padding: 15px;
    background-color: var(--white-color);
    border-radius: 8px 8px 8px 8px;

    img {
      margin-right: 10px;
    }

    h2 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: var(--blue-gray);

      span {
        color: var(--trilha-metodologica);
      }
    }

    p {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 0px !important;
      color: var(--secondary-color);
    }

    .coin-trilha {
      width: 16px;
      height: 16px;
      margin-left: 10px;
    }

    h5 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: var(--dark);
    }
  }

  .block-happy-code {
    padding: 15px;
    background-color: var(--white-color);
    border-radius: 8px 8px 8px 8px;

    img {
      margin-right: 10px;
    }

    h2 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: var(--blue-gray);

      span {
        color: var(--happy-code);
      }
    }

    p {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 0px !important;
      color: var(--secondary-color);
    }

    .coin-trilha {
      width: 16px;
      height: 16px;
      margin-left: 10px;
    }

    h5 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: var(--dark);
    }
  }

  .block-happy-speech {
    padding: 15px;
    background-color: var(--white-color);
    border-radius: 8px 8px 8px 8px;

    img {
      margin-right: 10px;
    }

    h2 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: var(--blue-gray);

      span {
        color: var(--happy-speech);
      }
    }

    p {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 0px !important;
      color: var(--secondary-color);
    }

    .coin-trilha {
      width: 16px;
      height: 16px;
      margin-left: 10px;
    }

    h5 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: var(--dark);
    }
  }

  .block-happy-money {
    padding: 15px;
    background-color: var(--white-color);
    border-radius: 8px 8px 8px 8px;

    img {
      margin-right: 10px;
    }

    h2 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: var(--blue-gray);

      span {
        color: var(--happy-money);
      }
    }

    p {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 0px !important;
      color: var(--secondary-color);
    }

    .coin-trilha {
      width: 16px;
      height: 16px;
      margin-left: 10px;
    }

    h5 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: var(--dark);
    }
  }
}

@media screen and (min-width: 1301px) {
  .hamburger {
    display: none;
  }
}

@media (min-width: 1201px) and (max-width: 1300px) {
  .hamburger {
    display: none;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .dashboard {
    .select-box {
      width: 17%;
    }
  }
  .curso-digitais,
  .curso-analitica,
  .curso-socio,
  .curso-metodologica,
  .curso-happycode,
  .curso-happyspeech,
  .curso-happymoney,
  .curso-speech,
  .curso-code {
    width: 30%;
  }

  .hamburger {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .dashboard {
    .header {
      flex-direction: column;
      .coins {
        margin-bottom: 15px;
      }
    }

    .green-box,
    .yellow-box,
    .red-box,
    .blue-box {
      width: 49%;
      margin-bottom: 10px;
    }

    .white-box {
      width: 100%;
      margin-bottom: 20px;
    }

    .white-box {
      width: 100%;
      margin-bottom: 20px;
    }

    .input-box {
      margin-bottom: 10px;
    }

    .select-box {
      width: 100%;
      margin-bottom: 10px;
      margin-left: 0px;
    }
  }

  .hamburger {
    display: none;
  }

  .curso-digitais,
  .curso-analitica,
  .curso-socio,
  .curso-metodologica,
  .curso-happycode,
  .curso-happyspeech,
  .curso-happymoney,
  .curso-speech,
  .curso-code {
    width: 46%;
  }

  #box-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media screen and (max-width: 767px) {
  #header {
    display: none;
  }

  .container {
    justify-content: center;
  }

  .dashboard {
    .header {
      flex-direction: column;
      .coins {
        margin-bottom: 15px;
      }
    }

    .green-box,
    .yellow-box,
    .red-box,
    .blue-box {
      width: 100%;
      margin-bottom: 10px;
    }

    .white-box {
      width: 100%;
      margin-bottom: 20px;
    }

    .input-box {
      margin-bottom: 10px;
    }

    .select-box {
      width: 100%;
      margin-bottom: 10px;
      margin-left: 0px;
    }
  }

  .curso-digitais,
  .curso-analitica,
  .curso-socio,
  .curso-metodologica,
  .curso-happycode,
  .curso-happyspeech,
  .curso-happymoney,
  .curso-speech,
  .curso-code {
    width: 100%;
  }

  #box-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .hamburger {
    z-index: 1500;
    position: absolute;
    left: 10px;
    top: 10px;
  }
}

@media screen and (max-width: 630px) {
  .dashboard {
    padding: 60px 0px 60px 0px;

    #box-vertical-mentoria {
      flex-direction: column;

      .w-50 {
        width: 100%;
      }
    }

    #box-andamento-home {
      flex-direction: column;
      .progresso {
        margin-bottom: 10px;
      }
    }
  }
}

@media screen and (max-width: 520px) {
  #profile {
    flex-direction: column;
    margin-bottom: 15px;
  }

  .dashboard {
    .box-left .ant-radio-group {
      display: grid;
    }
    .box-info-aula {
      flex-direction: column;
      .carga {
        width: 100%;
        padding-left: 0px !important;
        border-right: 0px !important;
        margin-bottom: 10px;
      }
      .aula-coins {
        width: 100%;
        padding-left: 0px !important;
        border-right: 0px !important;
        margin-bottom: 10px;
      }
      .minister {
        width: 100%;
        padding-left: 0px !important;
        border-right: 0px !important;
        margin-bottom: 10px;
      }
    }
    .box-white {
      .day {
        width: 30px !important;
        h4 {
          font-size: 10px !important;
        }
      }
    }
  }

  #box-chat {
    width: 100% !important;
  }

  .box-historico-50 {
    width: 100%;
    margin-bottom: 10px;
  }

  #box-historico-mentoria {
    max-height: 800px !important;
  }

  #mentores-title {
    flex-direction: column;
  }

  #trilhas-column {
    flex-direction: column;
  }
}
