.aula-informacao-box {
  background-color: var(--white-color);

  padding: 16px;

  border-radius: 8px;

  display: flex;
  flex-direction: column;
  gap: 8px;

  width: 100%;
}

.aula-informacao-box__titulo {
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: var(--dark);
}

.aula-informacao-box__data-info-icones {
  display: flex;
  align-items: center;
  gap: 24px;
}

.data-info-icones__info-icones {
  display: flex;
  align-items: center;
  gap: 4px;

  svg {
    color: #6a7c96;
    width: 20px;
    height: 20px;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    color: #6a7c96;
  }
}
