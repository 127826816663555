.sidebar-and-container {
  display: flex;
}

.sidebar-and-container__container {
  width: 100%;

  padding: 56px 104px 56px 168px;

  display: flex;
  flex-direction: column;
  gap: 32px;
}
