.container-blue {
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 50%;
  z-index: 1;
}

.container-login {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--white-color);

  .logo-login {
    position: relative;
    max-width: 350px;
    height: auto;
    z-index: 4;
  }

  .img-layout {
    height: 80%;
    width: auto;
    bottom: 0px;
    left: 0px;
  }

  .img-people {
    position: absolute;
    height: 85%;
    width: auto;
    bottom: 0px;
    left: 0px;
    z-index: 3;
  }

  .formulario-box {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('../../assets/img/login/img-login.jpg');
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 3;
    gap: 5%;

    .formulario {
      width: 490px;
      height: auto;

      .arrow {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 28px;
        //background-color: var(--purple-theme);

        span {
          font-family: 'Roboto-Regular';
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          color: var(--white-color);
          margin-left: 16px;
        }
      }

      h3 {
        font-family: 'Roboto-Regular';
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        color: var(--white-color);
        margin-bottom: 8px;
      }

      p {
        font-family: 'Roboto-Regular';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: var(--secondary-color);
        margin-bottom: 16px;
      }

      .input {
        position: relative;
        width: 100%;
        height: 56px;
        background: #ffffff;
        border: 1px solid #ebeffc;
        box-sizing: border-box;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 22px !important;
        margin-bottom: 24px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: rgba(52, 68, 83, 0.5);

        .iconForm {
          margin-right: 8px;
        }

        input {
          width: 100%;
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #6a7c96;
          border: 0px solid;
        }
      }

      .input-error {
        position: absolute;
        top: 56px;
        font-family: 'Open Sans';
        color: #e84118;
        font-style: normal;
        font-weight: 600;
      }

      .lembrete {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .checkbox {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          input {
            background: #ffffff;
            border: 1px solid #cbd4ed;
            box-sizing: border-box;
            border-radius: 2px;
            margin-right: 7px;
          }

          span {
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #4c5a68;
          }
        }
      }

      button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 24px;
        width: 100%;
        height: 56px;
        background: var(--purple-theme) !important;
        border-radius: 4px;
        margin-top: 24px;
        margin-bottom: 24px;
        cursor: pointer;
        opacity: 0.92;
        transition: 0.3s;
        border: 0px solid;

        span {
          font-family: 'Roboto-Regular';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: var(--white-color);
        }
      }

      button:hover {
        background-color: var(--purple-theme-hover) !important;
        span{
          color: var(--purple-theme-light);
          transition: ease-in-out 0.5s;
        }
      }

      .conta {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: var(--white-color);
      }

      .cadastro {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: var(--green-color);
      }

      .logos-empresas{
        display: flex;
        justify-content: space-around;
        img{
          max-width: 150px;
        }
      }
    }
    .forget-password{
      display: flex;
      flex-direction: column;
      gap: 10px;

      button{
        margin-top: 2%;
      }
    }
  }
}

#green-happy {
  color: var(--purple-theme-light);
}

/* Escolher Unidade */

.boxes {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.boxes .box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto 16px;
  width: 100%;
  height: 120px;
  background: #ffffff;
  border: 1px solid #cacddf;
  box-sizing: border-box;
  border-radius: 8px;
}

.boxes .box p {
  margin-top: 16px;
}

.boxes .box:hover {
  background: var(--primary-color);
  color: #ffffff;
}

.boxes-login {
  display: flex;
  flex-direction: row;
  position: relative;
}

.box-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto 16px;
  width: 200px;
  height: 120px;
  background: #ffffff;
  border: 1px solid #cacddf;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
}

.box-login:hover {
  background: var(--primary-color) !important;
  color: white !important;
}

.box-login:hover svg {
  fill: white !important;
}
