.conecta-card {
  width: 100%;
  height: auto;

  background-color: var(--white-color);

  border-radius: 8px;

  padding: 16px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
}

.conecta-card__conecta-image-title {
  display: flex;
  flex-direction: column;
  gap: 12px;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 4px;
  }
}

.conecta-image-title__title {
  color: #344453;
  font-size: 18px;
  font-weight: 600;
}
