.container-wrapper {
  width: 100%;

  padding: 56px 104px 56px 168px;

  display: flex;
  flex-direction: column;
  gap: 32px;
}

.increase-top-padding {
  padding-top: calc(56px + 88px)
}
