.curso-card-container {
  width: 100%;
  height: auto;

  background-color: var(--white-color);

  border-radius: 8px;

  padding: 16px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
}

.curso-card-container__curso-informations {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;

  height: 100%;
}

.curso-informations__thumbnail-title {
  display: flex;
  flex-direction: column;
  gap: 12px;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 4px;
  }
}

.thumbnail-title__course-title {
  color: #344453;
  font-size: 18px;
  font-weight: 600;
}

.curso-informations__teacher-schedule-reward {
  display: flex;
  flex-direction: row;
}

.curso-informations__schedule-coins {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.curso-informations__curso-info {
  display: flex;
  align-items: center;
  gap: 8px;

  width: fit-content;
}

.curso-info__thumbnail > div {
  width: 32px;
  height: 32px;
}

.curso-info__label-info {
  display: flex;
  flex-direction: column;
}

.course-info__label {
  color: #34445390;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;

  width: fit-content;
}

.course-info__info {
  color: #344453;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  width: fit-content;
}

.curso-info__coins {
  display: flex;
  align-items: center;
  gap: 4px;
}

.coin-trilha {
  width: 24px !important;
  height: 24px !important;
}