.turma-detalhe__head-buttons {
  display: flex;
  align-items: center;
  gap: 16px;

  width: 100%;
}

.box-data-turma-detalhe-porcentagem-aulas-wrapper {
  display: flex;
  align-items: center;
  gap: 32px;
}
