.sidebar-wrapper {
  transition: width, left, right, 300ms;

  background-color: var(--white-color);

  padding: 24px 16px;

  position: fixed;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  z-index: 100;

  height: 100vh;
}

.sidebar-wrapper__logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 150px;
  cursor: pointer;
  padding-bottom: 10px;
  img {
    max-height: 56px;
  }
}

.logo-container__button-menu {
  position: absolute;

  top: 52px;
  right: -32px;
  transform: translate(-50%, -50%);

  background-color: #ffffffe8;

  border-radius: 100px;

  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);

  * {
    color: var(--purple-theme-light);
  }

  &:hover * {
    background-color: #ffffff;
    color: var(--purple-theme-hover);
  }
}

.sidebar-wrapper__menu-items-list {
  height: 100%;
}

.sidebar-wrapper__menu-items-list,
.sidebar-wrapper__user-logout-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  width: 100%;
}

.user-logout-list__avatar-user {
  color: var(--purple-theme-extra-light);

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;

  padding: 16px;

  border-radius: 16px;

  cursor: pointer;

  width: 100%;

  div svg {
    transition: transform 0.3s ease-in-out;
    fill: var(--purple-theme-light);
  }

  &:hover {
    background-color: var(--purple-theme-extra-light);

    div svg {
      transform: scale(1.15);
      fill: var(--purple-theme-hover);
    }
  }
}

.user-logout-list__avatar-user-active {
  background-color: var(--purple-theme-extra-light);

  div svg{
    fill: var(--purple-theme-light);
  }
  
  * {
    color: var(--purple-theme-light);
  }
}

.avatar-user {
  display: flex;
  flex-direction: column;
  width: 100%;

  h6 {
    font-family: var(--main-font);

    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--azul-primario);

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 15ch;
  }

  p {
    font-family: var(--main-font);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--purple-theme-hover);
  }
}
