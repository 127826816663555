.arrow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 28px;
}

.arrow span {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #6a7c96;
  margin-left: 16px;
}

.password-requisitos {
  list-style-type: circle;
  font-family: 'Open Sans', sans-serif;
  color: var(--green-color);
  margin-left: 20px;
  margin-bottom: 20px;
}
