.indicator-card {
  display: flex;
  align-items: center;
  gap: 16px;

  padding: 16px;

  border-radius: 8px;

  img,
  svg {
    width: 48px;
    height: 48px;
  }
}

.indicator-card__card-stats {
  display: flex;
  flex-direction: column;

  color: var(--white-color);
}

.card-stats__stats-value {
  font-size: 36px;
  font-weight: 600;
}

.card-stats__stats-label {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
}
