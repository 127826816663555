.container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1{
    font-size: 28px;
    font-weight: bold;
    color: var(--primary-color);
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    padding: 12px 0px;
  }
  ul{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 18px;
    padding: 12px;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
}
.title{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  margin-bottom: 12px;
  font-size: 32px;
}
.subTitle{
  text-align: center;
  padding: 12px;
  font-weight: bold;
}
.itemContainer{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 20%;
}
.item{
  display: flex;
  gap: 18px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img{
    height: 100px;

    &:hover{
      cursor: pointer;
      transform: scale(1.1);
      transition: all 0.3s ease;
    }
  }
}

.subContainer{
  width: 100%;
}