.cursos-aulas-wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.cursos-aulas-wrapper__searchbar {
  position: relative;
}

.searchbar__icon {
  position: absolute;

  top: 50%;
  left: 24px;

  transform: translate(-50%, -50%);
}

.searchbar__input {
  width: 100%;
  height: 40px;

  padding-left: 40px !important;

  border-radius: 8px;

  background-color: var(--white-color);
}

.cursos-table__table-head {
  background-color: var(--white-color);
  height: 50px;
  padding: 5px 20px 5px 0;

  border-radius: 8px 8px 0 0;
}
