.steps-box__wrapper {
  background-color: var(--white-color);

  padding: 16px;

  border-radius: 8px;

  height: fit-content;
  width: calc(25% - 16px);

  display: flex;
  flex-direction: column;
  gap: 16px;

  h5 {
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    color: #1f2832;
  }
}
