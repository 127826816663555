.row-items {
  background-color: var(--white-color);
  height: 50px;
  padding: 5px 20px 5px 0px;
  height: 90px;

  .item {
    // margin-left: 20px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: var(--dark);

    a {
      margin: 0px;
    }

    .status-concluido {
      .concluido {
        width: 7px;
        height: 7px;
        border-radius: 4px;
        background-color: var(--check);
        margin-right: 5px;
      }

      p {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: var(--check);
      }
    }

    .status-andamento {
      .andamento {
        width: 7px;
        height: 7px;
        border-radius: 4px;
        background-color: var(--toast);
        margin-right: 5px;
      }

      p {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: var(--toast);
      }
    }
  }
}

.row-items:hover {
  background-color: rgba(203, 212, 237, 0.2);
}
