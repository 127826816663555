.instrutor__cards-wrapper {
  display: flex;
  flex-direction: column;

  gap: 16px;
}

.indicators__cards {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 24px;
}

.courses-cards-wrapper {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 24px;
}

@media (min-width: 640px) {
  .indicators__cards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .courses-cards-wrapper {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 768px) {
  .indicators__cards {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .courses-cards-wrapper {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .indicators__cards {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .courses-cards-wrapper {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
