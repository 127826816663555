.conteudo-aula-box {
  display: flex;
  flex-direction: column;
  gap: 16px;

  background-color: var(--white-color);

  border-radius: 8px;

  padding: 16px;

  width: 100%;
}

.conteudo-aula-box__info-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
