.class-card {
  background-color: var(--white-color);

  padding: 16px;

  border-radius: 8px;

  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 16px;
}

.class-card__class-card-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;

  height: 100%;
}

.class-card-wrapper__class-title-id {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.class-title-id__class-title {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  text-overflow: ellipsis;

  max-width: 100%;

  white-space: nowrap;

  overflow: hidden;
}

.class-title-id__class-id-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.class-id-status__class-id {
  color: #34445380;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.class-id-status__class-status {
  display: flex;
  align-items: center;
  gap: 4px;
}

.class-status__dot-red,
.class-status__dot-green {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.class-status__dot-red {
  background-color: var(--red-color);
}

.class-status__dot-green {
  background-color: var(--green-color);
}

.class-status__text-red,
.class-status__text-green {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
}

.class-status__text-red {
  color: var(--red-color);
}

.class-status__text-green {
  color: var(--green-color);
}

.class-card-wrapper__matriculation-length {
  color: #485d73;
  font-size: 14px;

  span {
    font-weight: 600;
  }
}

.class-card-wrapper__year-quantity {
  color: #34445380;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
}

.class-card-wrapper__schedules-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;

  overflow-x: auto;
}

.schedules-container__schedule {
  background-color: #b0cb5126;

  border-radius: 4px;

  padding: 8px 24px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.schedule__text {
  color: #627b08;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-transform: uppercase;
}
