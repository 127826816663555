.conectas-all-container__title-button-back {
  display: flex;
  align-items: center;
  gap: 16px;
}

.conectas-all-container__search-bar {
  width: 100%;
  height: fit-content;

  position: relative;

  display: flex;
  align-items: center;
}

.search-bar__icon {
  position: absolute;

  left: 12px;
  top: 50%;
  transform: translateY(-50%);

  display: flex;
  align-items: center;
  justify-content: center;
}

.search-bar__input {
  background-color: var(--white-color);

  width: 100%;
  height: 40px;

  padding: 8px 16px !important;
  padding-left: 40px !important;

  border-radius: 8px;
}

.webinars-all-container__cards-container {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 24px;
}

@media (min-width: 640px) {
  .webinars-all-container__cards-container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 768px) {
  .webinars-all-container__cards-container {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
