.trilha-card-container {
  background-color: var(--white-color);

  padding: 16px;

  border-radius: 8px;

  display: flex;
  flex-direction: column;
  gap: 24px;
}

.trilha-card-container__trilha-infos {
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 12px;
}

.trilha-infos__trilha-name-icon {
  display: flex;
  align-items: center;
  gap: 12px;
}

.trilha-name-icon__trilha-name {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;

  color: #34445390;
}

.trilha-infos__trilha-thumb img {
  width: 100%;
  height: auto;

  object-fit: cover;

  border-radius: 4px;
}

.trilha-card-container__description {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #344453;
}
