.icon-button {
  height: auto;
  width: auto;

  background-color: transparent;

  border-radius: 50%;

  transition: all 0.3s ease;

  box-shadow: none;

  padding: 8px;

  border: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-button__second {
  background-color: #ebeffc;

  svg {
    color: var(--primary-color);
  }

  &:hover {
    background-color: #d4d9f9;
  }
}

.icon-button__ghosted {
  svg {
    color: var(--primary-color);
  }

  &:hover {
    background-color: #f5f5f5;
  }
}
