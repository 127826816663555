.tag {
  width: auto;
  height: auto;
  padding: 5px 15px;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.todas {
  background-color: var(--blue-gray);

  span {
    color: var(--secondary-color);
    font-family: Open Sans;
    font-size: 14px;
    margin: 0px;
  }
}

.todas-select {
  background-color: var(--azul-primario);

  span {
    color: var(--white-color);
    font-family: Open Sans;
    font-size: 14px;
    margin: 0px;
  }
}

.trilha-label {
  background-color: #d8fbff;

  span {
    color: var(--secondary-color);
    font-family: Open Sans;
    font-size: 14px;
    margin: 0px;
  }
}

.trilha-metodologica {
  background-color: #ffeed4;

  span {
    color: var(--secondary-color);
    font-family: Open Sans;
    font-size: 14px;
    margin: 0px;
  }
}

.trilha-analitica {
  background-color: #fae0ff;

  span {
    color: var(--secondary-color);
    font-family: Open Sans;
    font-size: 14px;
    margin: 0px;
  }
}

.trilha-socio {
  background-color: #ffc7da;

  span {
    color: var(--secondary-color);
    font-family: Open Sans;
    font-size: 14px;
    margin: 0px;
  }
}

.trilha-socio-select {
  background-color: var(--trilha-socio);

  span {
    color: var(--white-color);
    font-family: Open Sans;
    font-size: 14px;
    margin: 0px;
  }
}

.trilha-happy-money {
  background-color: #ffe6b3;

  span {
    color: var(--secondary-color);
    font-family: Open Sans;
    font-size: 14px;
    margin: 0px;
  }
}

.trilha-happy-code {
  background-color: #f0facb;

  span {
    color: var(--secondary-color);
    font-family: Open Sans;
    font-size: 14px;
    margin: 0px;
  }
}

.trilha-happy-speech {
  background-color: #f3baba;

  span {
    color: var(--secondary-color);
    font-family: Open Sans;
    font-size: 14px;
    margin: 0px;
  }
}

.happy-code {
  background-color: rgba(243, 186, 39, 0.2);

  span {
    color: var(--happy-money);
    font-family: Open Sans;
    font-size: 14px;
    margin: 0px;
  }
}

.criatividade {
  background-color: var(--white-color);
  border: 1px solid var(--primary-color);
  border-radius: 4px;

  span {
    text-transform: uppercase;
    color: #252379;
    font-family: Open Sans;
    font-size: 12px;
    margin: 0px;
  }
}

.happy-money {
  background-color: rgba(243, 186, 39, 0.2);

  span {
    color: #f3ba27;
    font-family: Open Sans;
    font-size: 14px;
    margin: 0px;
  }
}

.happy-speech {
  background-color: rgba(49, 180, 250, 0.2);

  span {
    color: #31b4fa;
    font-family: Open Sans;
    font-size: 14px;
    margin: 0px;
  }
}

.happy-code-green {
  background-color: rgba(81, 199, 104, 0.2);

  span {
    color: #51c768;
    font-family: Open Sans;
    font-size: 14px;
    margin: 0px;
  }
}

.metodologia {
  background-color: rgba(255, 151, 91, 0.2);

  span {
    color: #cb8808;
    font-family: Open Sans;
    font-size: 14px;
    margin: 0px;
  }
}
