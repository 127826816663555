.materiais-container__top-materiais-informations {
  display: flex;
  justify-content: space-between;
}

.top-materiais-informations__title-label {
  display: flex;
  flex-direction: column;
  gap: 4px;

  p {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: #34445380;
  }
}

.top-materiais-information__select-tipo {
  display: flex;
  flex-direction: column;
  gap: 4px;

  label {
    color: var(--dark);
    font-size: 12px;
    line-height: 20px;
  }
}

.materiais-container__table {
  background-color: var(--white-color);

  border-radius: 8px;
}
