.box-mentor {
  width: 32%;
  height: auto;
  padding: 16px;
  background: var(--white-color);
  border: 1px solid #ebeffc;
  box-sizing: border-box;
  border-radius: 8px 8px 0px 0px;
  margin-bottom: 20px;

  img {
    width: 80px;
    height: auto;
    margin-right: 20px;
  }

  .info-mentor {
    h3 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;
      color: var(--dark);
    }

    p {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 22px;
      color: var(--dark);
    }
  }
}

.box-mini-mentor {
  background: #ffffff;
  border: 1px solid #ebeffc;
  box-sizing: border-box;
  border-radius: 8px 8px 0px 0px;
  padding: 10px;

  img {
    width: 40px;
    height: auto;
    margin-right: 15px;
  }

  .info-mentor {
    h3 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;
      color: #1f2832;
    }

    p {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 26px;
      color: var(--dark);
    }
  }
}
