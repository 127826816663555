.frequencia-section {
  display: flex;
  gap: 24px;

  width: 100%;

  padding: 16px;
}

.frequencia-section__select-input {
  display: flex;
  flex-direction: column;
  gap: 4px;

  width: calc((100% / 3) - 8px);
}

.select-input__label {
  color: var(--dark-secondary);
  font-size: 12px;
}

.select-input__input {
  height: 40px;

  background-color: #F5F5F9;

  border: 1px solid rgba(26, 77, 161, 0.15);
  border-radius: 8px;
}
