.product-type-table-row {
  display: flex;
  align-items: center;
  gap: 16px;

  padding: 12px 16px;

  border-bottom: 1px solid #ebeffc;
  display: flex;
  width: 100%;
}

.product-type-table-row__thumb-course-description {
  display: flex;
  align-items: center;
  gap: 12px;

  width: 40%;

  img {
    width: 40px;
    height: 40px;
  }
}

.thumb-course-description__description,
.product-type-table-row__item {
  color: var(--dark);
  font-size: 14px;
  font-weight: 500;
}

.product-type-table-row__item {
  width: 20%;
}

.product-type-table-row__button {
  width: fit-content !important;

  margin-left: auto;
}
