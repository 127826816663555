.tag-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: fit-content;
  height: auto;

  padding: 8px 16px;

  border-radius: 100px;

  span {
    margin-left: 5px;

    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.tag-container-sede {
  background: rgba(127, 211, 143, 0.2);

  span {
    color: #478953;
  }
}

.tag-container-admin {
  background: rgba(110, 194, 201, 0.2);

  span {
    color: #306f75;
  }
}

.tag-container-colaborador {
  background: rgba(255, 151, 91, 0.2);

  span {
    color: #cf6d34;
  }
}

.tag-container-franquia {
  background: rgba(207, 107, 108, 0.2);

  span {
    color: #aa5556;
  }
}

.tag-container-instrutor {
  background: rgba(109, 180, 248, 0.2);

  span {
    color: #3771a8;
  }
}

.tag-container-gestor {
  background: rgba(184, 154, 253, 0.2);

  span {
    color: #895cf2;
  }
}

.tag-container-comercial {
  background: rgba(250, 138, 139, 0.2);

  span {
    color: #cf6b6c;
  }
}

.tag-games {
  background: rgba(243, 186, 39, 0.2);

  span {
    color: #f3ba27;
  }
}

.tag-youtube {
  background: #facbcb;

  span {
    color: #f56b6c;
  }
}
