.user-welcome-card {
  display: flex;
  flex-direction: column;
  gap: 4px;

  background-color: var(--white-color);

  border-radius: 8px;

  width: 100%;

  padding: 16px;
}

.user-welcome-card__saudation {
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--dark);

  margin-bottom: 0px;
}

.user-welcome-card__hello-name {
  display: flex;
  gap: 4px;

  h2 {
    font-size: 26px;
    line-height: 36px;
    color: var(--dark);
  }
}

.hello-name__hello {
  color: var(--blue-gray) !important;
}
