.status-turma {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.status-turma div {
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

.dot-orange {
  background: var(--orange-color);
}

.dot-green {
  background: var(--green-color);
}

.ativo {
  color: var(--green-color);
}

.inativo {
  color: var(--orange-color);
}

.ativo,
.inativo {
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
}
