.trilha-accordion {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.trilha-accordion__top-accordion {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: transparent;

  padding: 12px;

  border-radius: 8px;

  &:hover {
    background-color: #edeff3;
  }
}

.top-accordion__icon-trilha-name {
  display: flex;
  align-items: center;
  gap: 12px;
}

.icon-trilha-name__trilha-name {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: rgba(52, 68, 83, 0.5647058824);
}

.top-accordion__rewards-coins-chevron-icon {
  display: flex;
  align-items: center;
  gap: 40px;
}

.rewards-coins-chevron-icon__rewards-coins {
  display: flex;
  align-items: center;
  gap: 12px;
}

.rewards-coins__label {
  color: rgba(52, 68, 83, 0.5647058824);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.rewards-coins__coins-value-wrapper {
  display: flex;
  align-items: center;
  gap: 4px;

  p {
    color: var(--dark);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  img {
    width: 16px;
    height: 16px;
  }
}

.chevron-accordion-icon {
  background-color: #ebeffc;

  height: auto;
  width: auto;

  border: 0px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;

  box-shadow: none;
}

.trilha-accordion__bottom-card-container {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 24px;
}

@media (min-width: 640px) {
  .indicators__cards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .trilha-accordion__bottom-card-container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 768px) {
  .indicators__cards {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .trilha-accordion__bottom-card-container {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .indicators__cards {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .trilha-accordion__bottom-card-container {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
