.sidebar-closed-menu-item {
  color: var(--purple-theme-light);

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  padding: 16px;

  border-radius: 16px;

  cursor: pointer;

  background-color: transparent;

  width: 100%;

  &:hover {
    background-color: var(--purple-theme-extra-light);
    color: var(--purple-theme-hover);
    .sidebar-item__icon {
      transform: scale(1.2);
      color: var(--purple-theme-hover);
    }
  }
}

.sidebar-closed-menu-item-actived {
  background-color: var(--purple-theme-extra-light);
  color: var(--purple-theme-hover);

  .sidebar-item__icon {
    color: var(--purple-theme-hover);
  }
}

.sidebar-item__icon {
  display: flex;
  justify-content: center;
  align-items: center;

  transition: transform 0.3s ease-in-out;

  color: var(--purple-theme-light);

  * {
    width: 20px;
    height: 20px;
  }
}

.menu-item__label {
  font-family: var(--main-font);
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;

  text-align: left;

  width: 100%;
}
