.lancamento-aula__title-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-button__title-small-tag {
  display: flex;
  align-items: baseline;
  gap: 12px;

  small {
    color: var(--dark-secondary);

    font-size: 14px;
  }
}

.lancamento-aula-wrapper {
  display: flex;
  gap: 32px;

  width: 100%;
}

.lancamento-aula-wrapper__aula-information-steps-container {
  display: flex;
  flex-direction: column;
  gap: 32px;

  width: calc(35% - 16px);
}

.lancamento-aula-wrapper__title-conteudo-ministrado-form {
  display: flex;
  flex-direction: column;
  gap: 32px;

  width: calc(70% - 16px);
}


.conteudo-ministrado-form__buttons-actions {
  display: flex;
  justify-content: flex-end;
  gap: 16px;

  width: 100%;
}
