.aluno-table__aluno-cell {
  display: flex;
  flex-direction: column;
  gap: 4px;

  width: 50%;
}

.aluno-cell__aluno-nome {
  font-size: 14px;
  line-height: 24px;
  color: #344453;
}
