.accordion-wrapper {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  cursor: pointer;
}

.accordion-wrapper__conteudo-topo {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 8px 0;

  border-bottom: 1px solid #e0e0e0;
}

.conteudo-topo__icon-titulo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.icon-titulo__icon * {
  height: 16px;
  width: 16px;

  color: #344453;

  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-titulo__titulo {
  font-size: 18px;
  line-height: 27px;
  font-weight: normal;
  color: #344453;
}

.conteudo-topo__info {
  font-size: 14px;
  line-height: 21px;
  color: #344453;
}

.accordion-wrapper__conteudo-filho {
  width: 100%;

  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 16px;
}
