.product-type-table-header {
  display: flex;

  padding: 8px 16px;

  li {
    align-items: center;
    color: #6a7c96;

    display: flex;
    gap: 4px;

    font-size: 12px;
    font-weight: 600;
    line-height: 1.5;
    padding: 8px 0;
    text-transform: uppercase;
  }

  border-bottom: 1px solid #ebeffc;
  display: flex;
  width: 100%;
}
