.pdf-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 100vw;
  height: 100vh !important;
}

.pdf {
  width: 60%;
  height: 90vh;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
  background-color: #ffff;
}

.buttonToolbar {
  background-color: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 10px;
}
.buttonToolbar:hover {
  background-color: rgba(53, 127, 221, 0.2);
}

.left-toolbar {
  width: 33%;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.center-toolbar {
  width: 33%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.right-toolbar {
  width: 33%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}

.container-toolbar-item {
  padding: 0px 2px;
}
.note {
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  min-width: 100%;
}

.note-header {
  display: flex;
  align-items: center;
  overflow: auto;
}

.note-header h3 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  margin-right: 10px;
}

.note-header span {
  font-size: 12px;
  color: #999;
}

.note-content {
  margin-top: 10px;
  font-size: 14px;
  color: #333;
  overflow-wrap: break-word;
  word-break: break-all;
}

.container-notes {
  overflow: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selection-region {
  background: #eee;
  display: flex;
  position: absolute;
  transform: translate(0, 8px);
  z-index: 100;
}
.rpv-full-screen__overlay {
  display: none;
}
