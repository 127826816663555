.generic-table-wrapper {
  width: 100%;

  background-color: #ffffff;

  padding: 16px;

  border-radius: 8px;

  margin-bottom: 16px;
}

.generic-table-wrapper__header-cell {
  color: #6a7c96;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-transform: uppercase;
  text-align: left;

  display: table-cell;
  vertical-align: middle;

  height: 48px;
}

.generic-table-wrapper__body-cell {
  color: #344453;
  font-size: 14px;
  line-height: 20px;

  display: table-cell;
  vertical-align: middle;
}

.generic-table-wrapper__body-cell,
.generic-table-wrapper__action-cell {
  height: 64px;
}

.generic-table-wrapper__action-cell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.generic-table-wrapper__divider-row td {
  border-bottom: 1px solid var(--silver-base);
  padding: 0;
}

