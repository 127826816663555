.student-skill-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.student-skill-wrapper__title {
  font-size: 24px;
  font-weight: 600;
  color: var(--dark);
}

